import { EyeOutlined } from '@ant-design/icons';
import Skeleton from '@ant-design/pro-skeleton';
import {
  getDefaultSortOrder,
  TagField,
  useTableReturnType,
} from '@refinedev/antd';
import { getDefaultFilter, HttpError, useGo } from '@refinedev/core';
import { Button, Flex, Form, Input, Space, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ErrorComponent } from '../../pages/error/404';
import { DealStatus } from '../../pages/partner/vo/submission';
import { Deal } from '../../utils/deal-base.dto';
import { debounce } from '../../utils/debouence';
import { DEFAULT_PIPEDRIVE_LINK } from '../../utils/constants';

const { Search } = Input;

const { Text, Title, Link } = Typography;

type CarsListProps = {
  tableData: useTableReturnType<Deal, HttpError, { search: string }>;
  isLoading: boolean;
  isError?: number | boolean;
};

export const CarsList = ({ tableData, isLoading, isError }: CarsListProps) => {
  const { searchFormProps, sorters, filters, tableProps } = tableData;
  const { t } = useTranslation();
  const go = useGo();

  return (
    <>
      {isLoading ? (
        <Skeleton type="result" />
      ) : isError ? (
        <ErrorComponent />
      ) : (
        <Flex
          style={{ maxWidth: 1440, margin: '0 auto', padding: 24 }}
          vertical={true}
          gap={'small'}
          align={'center'}
        >
          <Title level={1}>{t('page.cars.pageMetaTitle')}</Title>

          <Flex
            style={{
              width: '100%',
              maxWidth: 1140,
            }}
            align={'center'}
            wrap={'wrap-reverse'}
            gap={'small'}
            justify={'space-between'}
          >
            <Form {...searchFormProps} layout="inline">
              <Form.Item name="search">
                <Search
                  style={{
                    minWidth: '320px',
                  }}
                  size={'large'}
                  loading={isLoading}
                  onChange={debounce(searchFormProps.form?.submit, 400)}
                  placeholder={t(t('page.cars.admin.searchByText'))}
                />
              </Form.Item>
            </Form>
          </Flex>

          <Table
            {...tableProps}
            scroll={{ x: 1140 }}
            locale={{
              emptyText: t('common.noDataText'),
              filterReset: t('common.reset'),
              filterCheckall: t('common.selectAll'),
            }}
            rowKey="id"
          >
            <Table.Column
              width={160}
              render={(deal: Deal) => <Text>{deal.vin ?? 'N/A'}</Text>}
              title={t('common.vin')}
              ellipsis={true}
            />
            <Table.Column
              width={100}
              dataIndex="getCaradsId"
              title={t('common.caradsId')}
              ellipsis={true}
              render={(caradsId?: number) => {
              return <Text>{caradsId ?? 'N/A'}</Text>}}
            />
            <Table.Column
              dataIndex="model"
              title={t('common.carModel')}
              width={80}
              ellipsis={true}
            />
            <Table.Column
              width={120}
              render={(deal: Deal) => (
                <Text>{deal.getSellerName ?? 'N/A'}</Text>
              )}
              title={t('common.client.name')}
            />
            <Table.Column
              width={160}
              render={(deal: Deal) => (
                <Text>{deal.getSellerEmail ?? 'N/A'}</Text>
              )}
              title={t('common.client.email')}
            />

            <Table.Column
              width={120}
              defaultFilteredValue={getDefaultFilter('status', filters)}
              filterMode={'tree'}
              filters={Object.values(DealStatus).map(status => ({
                text: t(`page.partnerPortal.table.status.${status}`),
                value: status,
              }))}
              dataIndex="status"
              title={t('common.status')}
              render={(value: string) => (
                <TagField
                  bordered={false}
                  value={t(`page.partnerPortal.table.status.${value}`)}
                  color={
                    value === DealStatus.WAITING_PRICE_ACCEPTANCE
                      ? 'orange'
                      : 'blue'
                  }
                />
              )}
            />
            <Table.Column
              width={100}
              dataIndex="createdAt"
              title={t('common.createdAt')}
              sorter
              defaultSortOrder={getDefaultSortOrder('createdAt', sorters)}
              showSorterTooltip={false}
              render={(value: string) => (
                <Text>
                  {new Date(value).toLocaleDateString()}{' '}
                  {new Date(value).toLocaleTimeString()}
                </Text>
              )}
            />

            <Table.Column
              width={110}
              title={t('page.admin.deal.list.fields.details')}
              render={(deal: Deal) => {
                return (
                  <Space direction={'horizontal'} wrap={true}>
                    <Button
                      onClick={() => go({ to: `/admin/biler/${deal.id}` })}
                      type={'text'}
                      shape={'circle'}
                      icon={<EyeOutlined />}
                    />
                  </Space>
                );
              }}
            />

             <Table.Column
              width={100}
              title={t('page.admin.deal.list.fields.pipedrive')}
              render={(deal: Deal) => {
                return (
                  <>{deal?.getPipedriveDealId ? <Space direction={'horizontal'} wrap={true}>
                    <Link
                      href={`${DEFAULT_PIPEDRIVE_LINK}/deal/${deal.getPipedriveDealId}`}
                    >
                      {' '}
                      <Button
                        target={'_blank'}
                        rel="noopener noreferrer"
                        type={'text'}
                        shape={'circle'}
                        icon={<EyeOutlined />}
                      />
                    </Link>
                  </Space> : <Text>N/A</Text>}
                  </>
                );
              }}
            />
          </Table>
        </Flex>
      )}
    </>
  );
};
