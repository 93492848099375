import { useGo } from '@refinedev/core';
import { Alert, Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';

export const ResetPasswordFailed = () => {
  const { t } = useTranslation();
  const go = useGo();

  return (
    <Space size={'small'} direction="vertical">
      <Alert
        message={t('page.auth.resetPassword.failed')}
        type="error"
        showIcon
      />
      <Button
        type="primary"
        size="large"
        block
        onClick={() => go({ to: '/auth' })}
      >
        {t('page.auth.forgotPassword.backToLogin')}
      </Button>
    </Space>
  );
};
