import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from 'antd';



const NoDataWrapper = styled.div`
    width: 100%;
    height: 100%;
`

const Center = styled.p`
    margin: 0 auto;
    text-align: center;
`

export const NoDataYetText: React.FC<{
  i18nKey?: string;
}> = ({ i18nKey = 'global.noData' }) => {

  const { Text } = Typography;


  const { t } = useTranslation();

  return (
    <NoDataWrapper>
      <Center>
      <Text
        type={'secondary'}>
        {t(i18nKey)}
      </Text>

      </Center>
    </NoDataWrapper>
  );
}