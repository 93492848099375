import { danishLocale } from '../locales/da/da_DK';

const locales = {
  da: danishLocale,
} as const;

type LocaleKeys = keyof typeof locales;

function isLocaleKey(key: string): key is LocaleKeys {
  return key in locales;
}

export const getAntdLocale = (locale: string) => {
  if (isLocaleKey(locale)) {
    return locales[locale];
  }
  return danishLocale;
};