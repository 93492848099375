import { CrudFilter, CrudOperators } from '@refinedev/core';

const mapOperator = (operator: CrudOperators, value: unknown): string => {

  if(operator === 'eq' && Array.isArray(value)) {
    return 'in';
  }

  switch (operator) {
    case 'ne':
      return `not`;
    case 'between':
      return `btw`;
    case 'startswith':
      return `sw`;
    case 'ncontains':
      return `ilike`;
    case 'gte':
    case 'null':
    case 'in':
    case 'gt':
    case 'lt':
    case 'eq':
    case 'contains':
    case 'lte':
      return `${operator}`;
    default:
      throw new Error('Can not map operator');
  }
};

export function createSingleFilterQuery(filter: CrudFilter, parentKey: string = ''): string[] {
  if ('field' in filter) {
    // LogicalFilter case
    const fullPath = parentKey ? `filter.${parentKey}=${filter.field}` : `filter.${filter.field}`;

    return [`${fullPath}=$${mapOperator(filter.operator, filter.value)}:${filter.value}`];
  } else {
    // ConditionalFilter case
    return filter.value.flatMap((f: CrudFilter) => createSingleFilterQuery(f, filter.key));
  }
}