import { Divider, Flex, Space, Typography } from 'antd';
import { UploadImagesCard } from '../image-card';
import { List } from '../list/list';
import backSeatViewImage1 from '../../../../assets/images/picture-guide/bagsaede-incorrect1.webp';
import backSeatViewImageOk from '../../../../assets/images/picture-guide/bagsaede-correct.webp';
import backSeatViewImage2 from '../../../../assets/images/picture-guide/bagsaede-incorrect2.webp';
import backSeatViewImageOk2 from '../../../../assets/images/picture-guide/bagsaede-correct2.webp';
import { ImagesFileUploader, ImagesFileUploaderProps } from '../images-file-uploader';

const { Title } = Typography;

const advicePoints = [
  'Åben bagdøren.',
  'Find en vinkel, hvor man ser hele bredden af bagsædet og kan fornemme benpladsen.',
  'Tag gerne billede fra både højre og venstre side.',
];

export const BackSeatViewGuide = ({
  dealId,
  formRef,
  fileUploader,
  isLoading,
}: ImagesFileUploaderProps) => {
  return (
    <Flex vertical>
      <Flex justify="center">
        <Title level={3}>Bagsæder</Title>
      </Flex>
      <Space direction="vertical" size={'large'}>
        <List items={advicePoints} />
        <Flex gap={24} wrap justify='center' align="center">
          <Flex gap={24} style={{ justifyContent: 'center' }} wrap>
            <UploadImagesCard
              imageSrc={backSeatViewImage1}
              color="red"
            />
            <UploadImagesCard
              imageSrc={backSeatViewImage2}
              color="red"
            />
          </Flex>
          <Flex gap={24} style={{ justifyContent: 'center' }} wrap>
            <UploadImagesCard
              imageSrc={backSeatViewImageOk}
              color="green"
            />
            <UploadImagesCard
              imageSrc={backSeatViewImageOk2}
              color="green"
            />
          </Flex>
          <ImagesFileUploader
            name='back-seat'
            formRef={formRef}
            dealId={dealId}
            fileUploader={fileUploader}
            isLoading={isLoading}
            required={false}
            autoSave={true}
          />
          <Divider />
        </Flex>
      </Space>
    </Flex>
  );
};
