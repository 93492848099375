import { Divider, Flex, Space, Typography } from 'antd';
import { UploadImagesCard } from '../image-card';
import { List } from '../list/list';
import frontSeatImage1 from '../../../../assets/images/picture-guide/forsaede-incorrect1.webp';
import frontSeatImageOk from '../../../../assets/images/picture-guide/forsaede-correct.webp';
import frontSeatImage2 from '../../../../assets/images/picture-guide/forsaede-incorrect2.webp';
import frontSeatImageOk2 from '../../../../assets/images/picture-guide/forsaede-correct2.webp';
import { ImagesFileUploader, ImagesFileUploaderProps } from '../images-file-uploader';

const { Title } = Typography;

const advicePoints = [
  'Åben fordøren.',
  'Find en vinkel, hvor man kan se hele førersæder samt passagersædet.',
  'Tag gerne billede fra både fører- og passagerside',
];

export const FrontSeatViewGuide = ({
  dealId,
  formRef,
  fileUploader,
  isLoading,
}: ImagesFileUploaderProps) => {
  return (
    <Flex vertical>
      <Flex justify="center">
        <Title level={3}>Forsæder</Title>
      </Flex>
      <Space direction="vertical" size={'large'}>
        <List items={advicePoints} />
        <Flex gap={24} vertical align="center">
          <Flex gap={24} style={{ justifyContent: 'center' }} wrap>
            <UploadImagesCard
              imageSrc={frontSeatImage1}
              color="red"
            />
            <UploadImagesCard
              imageSrc={frontSeatImage2}
              color="red"
            />
          </Flex>
          <Flex gap={24} style={{ justifyContent: 'center' }} wrap>
            <UploadImagesCard
              imageSrc={frontSeatImageOk}
              color="green"
            />
            <UploadImagesCard
              imageSrc={frontSeatImageOk2}
              color="green"
            />
          </Flex>
          <ImagesFileUploader
            name='front-seat'
            formRef={formRef}
            autoSave={true}
            dealId={dealId}
            fileUploader={fileUploader}
            isLoading={isLoading}
          />
          <Divider />
        </Flex>
      </Space>
    </Flex>
  );
};
