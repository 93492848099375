import { AndroidOutlined, AppleFilled } from '@ant-design/icons';
import { CheckCard, ProForm, ProFormText } from '@ant-design/pro-components';
import { RefineThemedLayoutV2HeaderProps } from '@refinedev/antd';
import { HttpError, useCreate, useCustom, useParsed } from '@refinedev/core';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { Button, Col, Divider, Flex, Form, Result, Row, Space, Typography } from 'antd';
import Link from 'antd/es/typography/Link';
import Skeleton from '@ant-design/pro-skeleton';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CarInfo } from '../../components/car-info';
import { YesNoDontKnowOptionSelector } from '../../components/yes-or-no';
import { ICarSubmissionDetails } from '../../provider/dto/buyer-submission.dto';
import { isElectric } from '../../utils/deal-base.dto';
import { SubmissionDetails } from './vo/route-params';

const { Text, Title } = Typography;


export const CarBuyerPage: React.FC<RefineThemedLayoutV2HeaderProps> = () => {
  useDocumentTitle({ i18nKey: 'page.buyerCarForm.pageMetaTitle' });
  const { mutateAsync, isLoading: isLoadingCreate, isSuccess: isSubmitted } = useCreate();

  const { t } = useTranslation();

  const { params } = useParsed<SubmissionDetails>();

  const carAndBuyerDetails = useMemo(() => {
    if (params?.base64Id) {

      try {
        const base64Decoded = atob(params.base64Id);
        const decoded = JSON.parse(base64Decoded);

        if ((decoded.carId || decoded.dealId) && decoded.buyerId) {
          return {
            carId: decoded.carId,
            dealId: decoded.dealId,
            buyerId: decoded.buyerId,
          };
        }
      } catch (error) {
        return {};
      }
    }
  }, [params]);

  const { data, isLoading, isError } = useCustom<ICarSubmissionDetails, HttpError>({
    url: carAndBuyerDetails?.dealId ? `buyer-submission/car-submission-details/v2` : `buyer-submission/car-submission-details`,
    method: 'get',
    errorNotification: false,
    queryOptions: {
      enabled: !!carAndBuyerDetails?.carId || !!carAndBuyerDetails?.dealId,
    },
    config: {
      query: {
        ...carAndBuyerDetails,
      },
    },
  });

  const isElectricCar = isElectric(data?.data.deal?.fuel);
  const buyerId = data?.data.customerInfo.id;
  const dealId = data?.data.deal?.id;

  return (
     isLoading && (carAndBuyerDetails?.carId || carAndBuyerDetails?.dealId) ? <Skeleton type="result" /> :
      isError || ((!carAndBuyerDetails?.carId && !carAndBuyerDetails?.dealId) || !carAndBuyerDetails?.buyerId) ? <Result
          status="500"
          title={t('global.error')}
          subTitle={t('global.unexpectedError')}
          extra={<a href="mailto:hej@aksel.nu">{t('global.contactSupport')}</a>}
        ></Result> :
        (isSubmitted ?
          <Result
            status="success"
            title={t('page.buyerCarForm.successMessage')}
            subTitle={t('page.buyerCarForm.successSubMessage')}
            extra={[
              <Button type="primary" href={'https://aksel.nu'}>
                {t('global.home')}
              </Button>,
            ]}
          />
          : <ProForm
            onFinish={async (values) => {
              await mutateAsync({
                successNotification: () => false,
                resource: 'buyer-submission',
                values: {
                  ...values,
                  ...{
                    buyerId,
                    dealId
                  },
                  wantCarOwnershipInsuranceOffer: false,
                },
              });
            }}
            initialValues={{
              ...data?.data?.customerInfo
            }}

            submitter={{
              submitButtonProps: false,
              resetButtonProps: false,
              render: () => [],
            }}
            style={{ maxWidth: 820, margin: '0 auto', padding: 24 }}
          >
            <CarInfo {...data?.data.deal} />
            <Divider></Divider>
            <Title>{t('page.buyerCarForm.welcomeTitle')}</Title>


            <Space direction={'vertical'} size={'small'}>
              <Text>{t('page.buyerCarForm.welcomeDescription')}</Text>
              <Text>Vi har også lavet en enkel guide til dig, så du er forberedt, hvis fremvisningen skulle ende med et
                salg.</Text>

              <Title level={2}>Din guide til et nemt køb</Title>

              <Text>Vi håber selvfølgelig, at fremvisningen ender med, at du skal have en ny bil.</Text>
              <Text>Vi ved godt, at købs- og overdragelsesprocessen kan virke uoverskuelig, men faktisk er der kun
                ganske få ting, du skal have styr på.</Text>
              <Text>Og vi er klar til at hjælpe dig hele vejen igennem.</Text>
              <Text>{t('page.buyerCarForm.goodToDo.title')}</Text>

              <ol>
                {
                  (t('page.buyerCarForm.goodToDo.items', {
                    returnObjects: true,
                  }) as Array<{
                    caption: string;
                    description: string;
                  }>).map((l, idx) => {
                    return (<li>
                      <Text strong={true}>{l.caption}: </Text>
                      <Text>{l.description}</Text>

                      {idx === 1 &&
                        <div style={{
                          paddingTop: 8,
                          paddingBottom: 8,
                        }}>
                          <Space size={'small'} direction={'vertical'}>
                            <Link
                              href={'https://apps.apple.com/dk/app/ejerskifte/id1502217102'}
                              target={'_blank'}
                            ><AppleFilled style={{
                              fontSize: 24,
                              color: '#000',
                            }} /> {t('page.buyerCarForm.goodToDo.ownershipTransferAppName')} App Store</Link>
                            <Link
                              href={'https://play.google.com/store/apps/details?id=dk.ufst.ejerskifte'}
                              target={'_blank'}
                            >
                              <AndroidOutlined
                                style={{
                                  fontSize: 24,
                                  color: '#3DDC84',
                                }}
                              /> {t('page.buyerCarForm.goodToDo.ownershipTransferAppName')} Google Play</Link>
                          </Space>
                        </div>

                      }
                    </li>);
                  })
                }
              </ol>

            </Space>

            <Divider>{t('page.buyerCarForm.enterDetails')}</Divider>


            <Row
              gutter={[16, 0]}
            >
              <Col xs={24} sm={12}>
                <ProFormText
                  name="firstName"
                  label={t('common.user.firstName.label')}
                  placeholder={t('common.user.firstName.placeholder')}
                  rules={[{ required: true, message: t('global.requiredField') }]}
                />
              </Col>
              <Col xs={24} sm={12}>
                <ProFormText
                  name="lastName"
                  label={t('common.user.lastName.label')}
                  placeholder={t('common.user.lastName.placeholder')}
                  rules={[{ required: true, message: t('global.requiredField') }]}
                />
              </Col>

            </Row>

            <ProFormText
              name="email"
              label={t('common.user.email.label')}
              placeholder={t('common.user.email.placeholder')}
              rules={[{ required: true, message: t('global.requiredField') }]}
            />

            <ProFormText
              name="phoneNumber"
              label={t('common.user.phone.label')}
              placeholder={t('common.user.phone.placeholder')}
              rules={[{ required: true, message: t('global.requiredField') }]}
            />


            <Row
              gutter={[16, 0]}
            >
              <Col xs={24} sm={8}>
                <ProFormText
                  name="address"
                  label={t('common.user.address.label')}
                  placeholder={t('common.user.address.placeholder')}
                  rules={[{ required: true, message: t('global.requiredField') }]}
                />
              </Col>
              <Col xs={24} sm={8}>
                <ProFormText
                  name="city"
                  label={t('common.user.addressCity.label')}
                  placeholder={t('common.user.addressCity.placeholder')}
                  rules={[{ required: true, message: t('global.requiredField') }]}
                />
              </Col>
              <Col xs={24} sm={8}>
                <ProFormText
                  name="zipCode"
                  label={t('common.user.zipCode.label')}
                  placeholder={t('common.user.zipCode.placeholder')}
                  rules={[{ required: true, message: t('global.requiredField') }]}
                />
              </Col>

            </Row>

            <Divider>Finansiering</Divider>

            <Flex vertical={true} gap={'small'}>
              <Space direction={'vertical'} size={'middle'}>
                <Text>Gennem vores partner Santander kan vi tilbyde en attraktiv finansiering med virkelig gode
                  vilkår:</Text>

                <ul>
                  <li>I 9 ud af 10 tilfælde er vi billigere end banken</li>
                  <li>Vi kan finansiere både med og uden udbetaling</li>
                  <li>Ingen gebyrer ved indfrielse</li>
                  <li>Hurtig kreditvurdering – I nogle banker tager det op til 14 dage. Vi kan klare det på 5 min.</li>
                </ul>
              </Space>

              <Form.Item
                label={t('page.buyerCarForm.interestedInFinance')}
                name="wantFinanceOptions"
                rules={[{ required: true, message: t('global.requiredField') }]}
              >
                <CheckCard.Group
                  style={{ width: '100%' }}
                  size="small"
                >
                  <YesNoDontKnowOptionSelector />
                </CheckCard.Group>
              </Form.Item>
            </Flex>

            <Divider>Automatisk forsikring ved køb</Divider>
            <Flex vertical={true} gap={'small'}>
              <Text>Når du køber bil gennem Aksel.nu er du automatisk forsikret gennem Tryg de første 14 dage.</Text>
              <Text>Det er lovpligtigt at have bilen forsikret så snart handlet er gået igennem, men det kan godt være stressende at skulle nå at indhente forsikringstilbud samtidig med finansiering og ejerskifte.</Text>
              <Text>Derfor tilbyder Aksel.nu automatisk forsikring de første 14 dage.</Text>
              <Text>Der er ingen binding. Inden perioden udløber, ringer Tryg til dig med et uforpligtende tilbud, og så kan du selv afgøre om du ønsker at fortsætte eller gå me d et andet selskab. Vælger du Tryg får du 20% rabat gennem Aksel.nu.</Text>
              <Form.Item
                label={t('page.buyerCarForm.interestedInInsurance')}
                name="wantInsuranceOffer"
                rules={[{ required: true, message: t('global.requiredField') }]}
              >
                <CheckCard.Group
                  style={{ width: '100%' }}
                  size="small"
                >
                  <YesNoDontKnowOptionSelector />
                </CheckCard.Group>
              </Form.Item>
            </Flex>

            {(isElectricCar) && (<>
              <Divider>Ladestander</Divider>

              <Flex vertical={true} gap={'small'}>
                <Space direction={'vertical'} size={'middle'}>
                  <Space direction={'vertical'} size={'small'}>
                    <Text>Vi kan i samarbejde med Aura tilbyde en ladestander inkl. installation til kun 4.999 kr. med
                      meget favorable vilkår:</Text>

                    <ul>
                      <li>4.999,- inkl. standardinstallation - markedets stærkeste tilbud</li>
                      <li>Du ejer ladeboksen</li>
                      <li>Kun 30 dages binding</li>
                      <li>Serviceaftale fra kun 49,- /mdr.</li>
                      <li>Kundeservice i topklasse (4,6 på trustpilot, 4.000 anmeldelser)</li>
                      <li>24/7 servicesupport</li>
                      <li>Adgang til stort dansk og europæisk ladenetværk med app</li>
                      <li>En af markedets mest attraktive el-aftaler<Text type={'warning'}>*</Text></li>
                    </ul>
                    <Text type={'secondary'}><Text type={'warning'}>*</Text>Det er et krav for ladestandertilbuddet at
                      du også vælger Aura's elaftale</Text>

                  </Space>
                </Space>

                <Form.Item
                  label={'Vil du modtage et uforpligtende tilbud på ladestander fra Aura energi?'}
                  name="wantChargingStation"
                  rules={[{ required: true, message: t('global.requiredField') }]}
                >
                  <CheckCard.Group
                    style={{ width: '100%' }}
                    size="small"
                  >
                    <YesNoDontKnowOptionSelector />
                  </CheckCard.Group>
                </Form.Item>
              </Flex>
            </>)}

            <Form.Item>
              <Flex justify={'flex-end'}>
                <Button size={'large'} loading={isLoadingCreate} htmlType={'submit'} type={'primary'}
                        shape={'round'}>{t('global.submit')}</Button>
              </Flex>
            </Form.Item>

          </ProForm>)
  );
};