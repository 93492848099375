import { CSSProperties } from 'react';

export const antLayoutSider: CSSProperties = {
  position: 'relative',
};

export const drawerButtonStyles: CSSProperties = {
  position: 'absolute',
  top: 15,
  background: 'none',
  border: 0,
  padding: 0,
  boxShadow: 'none',
  zIndex: 999,
};