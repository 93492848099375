import { useGo, useIsAuthenticated } from '@refinedev/core';
import { AuthenticatedProps } from '@refinedev/core/dist/components/authenticated';
import { CatchAllNavigate } from '@refinedev/react-router-v6';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/auth';

const BASE_ADMIN_PORTAL_URL = 'admin';

export const AuthGuard = ({
  children,
  fallback,
  loading,
}: AuthenticatedProps) => {
  const { isLoading, data } = useIsAuthenticated();
  const { isAdmin } = useAuthContext();
  const go = useGo();
  const location = useLocation();

  if (isLoading) {
    return <>{loading}</> || null;
  }

  if (data?.error) {
    if (!fallback) {
      go({ to: '/auth', type: 'replace' });
      return null;
    }

    return <>{fallback}</>;
  }
  const isAdminPortal = location.pathname.includes(BASE_ADMIN_PORTAL_URL);

  if (data?.authenticated) {
    if (!isAdminPortal) {
      return <>{children}</>;
    } else if (isAdminPortal && !isAdmin) {
      return <CatchAllNavigate to={'/auth'} />;
    } else {
      return <>{children}</>;
    }
  }

  return <>{children ?? null}</>;
};
