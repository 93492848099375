import { useToken } from '@ant-design/pro-components';
import { RefineThemedLayoutV2HeaderProps } from '@refinedev/antd';
import { useParsed } from '@refinedev/core';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { Card, Layout, Space, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ResetPasswordFailed,
  ResetPasswordForm,
  ResetPasswordSuccess,
} from '../../components/auth/reset-password';
import { LoadingComponent } from '../../components/shared/loading';
import { ApprovalEmailParamsData } from '../../types/auth';
import { useValidateEmailParamsAndMutate } from './hooks/use-validate-email-params-and-mutate';
import { useIsMobile } from '../../hooks';

export const ResetPasswordPage: React.FC<
  RefineThemedLayoutV2HeaderProps
> = () => {
  useDocumentTitle({ i18nKey: 'page.auth.resetPassword.pageMetaTitle' });

  const { params } = useParsed<ApprovalEmailParamsData>();

  const { isIncorrectParams, isLoading, isError, isSuccess, refetch } =
    useValidateEmailParamsAndMutate(params, {
      resource: 'reset-password',
      enabled: false,
      validate: false
    });

  const { t } = useTranslation();
  const { token } = useToken();
  const { isMobileWithTablet } = useIsMobile();

  return (
    <>
      {isSuccess ? (
        <ResetPasswordSuccess />
      ) : (
        <Layout
          style={{
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: token.colorBgContainer,
          }}
        >
          <Content
            style={{ padding: isMobileWithTablet ? 0 : '16px', maxWidth: '600px', width: '100%' }}
          >
            <Card
              bordered={false}
              style={{
                borderRadius: '8px',
                boxShadow: token.boxShadowTertiary,
                padding: isMobileWithTablet ? 0 : '24px',
                textAlign: 'center',
              }}
            >
              <Typography.Title>
                {t('page.auth.resetPassword.welcomeTitle')}
              </Typography.Title>
              {isError || isIncorrectParams ? (
                <ResetPasswordFailed />
              ) : (
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: '100%' }}
                >
                  {isLoading && <LoadingComponent />}
                  {!isIncorrectParams && !isError && (
                    <>
                      <Space direction="vertical" size="small">
                        <Typography.Title
                          level={5}
                          style={{ fontWeight: '400' }}
                        >
                          {t('page.auth.resetPassword.description')}
                        </Typography.Title>
                      </Space>
                      <ResetPasswordForm refetch={refetch} params={params} />
                    </>
                  )}
                </Space>
              )}
            </Card>
          </Content>
        </Layout>
      )}
    </>
  );
};
