export const AkselIcon = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 516.84 217.39"
    >
      <defs>
        <style>
          {`
          .cls-1 {
            fill: #fff;
          }
          .cls-2 {
            fill: #fbc90e;
          }
        `}
        </style>
      </defs>
      <path
        className="cls-2"
        d="m493.65,142.14h0c-3.32-3.72-7.29-6.6-11.67-8.53.02-1.63.05-3.3.08-5.02v-.12c.05-2.63.09-5.35.09-8.12V49.88c0-9.67-3.67-18.67-10.35-25.36-6.68-6.67-15.69-10.35-25.36-10.35s-18.69,3.68-25.36,10.35c-6.67,6.67-10.35,15.68-10.35,25.36v11.41c-9.83-5.16-21.03-7.78-33.37-7.78-13.83,0-26.48,3.28-37.61,9.76-3.96,2.3-7.64,4.95-11.03,7.92-12.01-10.91-28.28-17.12-46.61-17.66-.79-.02-1.59-.03-2.38-.03h-.01c-9.03,0-17.8,1.47-26.06,4.37-3.59,1.26-6.99,2.8-10.17,4.62-6.03-5.77-13.78-9.16-22.14-9.62-.65-.03-1.25-.05-1.85-.05-7.17,0-13.9,2.13-19.73,6.2v-9.31c0-9.68-3.64-18.65-10.26-25.27-6.62-6.62-15.6-10.26-25.27-10.26s-18.65,3.64-25.27,10.26c-6.62,6.62-10.26,15.6-10.26,25.27v15.31c-.8-.51-1.62-1.01-2.45-1.5-11.27-6.63-23.97-10-37.72-10s-26.42,3.35-37.73,9.95c-11.21,6.55-20.21,15.59-26.73,26.85-6.57,11.34-9.9,24.09-9.9,37.91s3.01,25.96,8.95,37.03c6.05,11.3,14.51,20.41,25.12,27.09,10.98,6.91,23.38,10.42,36.88,10.42,8.82,0,17.21-1.51,25.05-4.5,5.04,2.63,10.69,3.99,16.69,3.99,6.78,0,13.24-1.81,18.81-5.21,5.49,3.29,11.86,5.04,18.58,5.04,9.68,0,18.65-3.64,25.27-10.26.82-.83,1.6-1.69,2.33-2.58l.73.9c6.18,7.62,14.91,12.23,24.59,12.98.93.07,1.86.11,2.76.11,8.21,0,15.98-2.79,22.46-8.04l.4-.33.03-.03c11.39,5.22,24.26,7.94,37.8,7.94,12.03,0,22.69-2.11,31.68-6.28,6.58-3.05,12.26-6.96,16.96-11.68,3.76,3.22,7.88,6.07,12.33,8.52,11.37,6.26,24.27,9.44,38.33,9.44,12.1,0,24.67-3.11,36.35-9,2.29-1.15,6.63-3.34,11.03-6.91,2.21,2.26,4.63,4.31,7.24,6.15,2.11,1.49,4.29,2.79,6.54,3.92l.26.39-.02-.27c6.8,3.35,14.25,5.04,22.23,5.04h.34c9.33,0,17.58-2.59,24.49-7.69,9.08-6.69,14.29-16.9,14.29-28.02,0-9.23-3.12-17.6-9.03-24.23Z"
      />
      <path
        className="cls-1"
        d="m401.99,83.8c-6.88-4.24-15.17-6.39-24.63-6.39s-18.16,2.19-25.59,6.51c-7.44,4.33-13.34,10.39-17.55,18.01-4.19,7.58-6.31,16.43-6.31,26.29s2.25,18.6,6.69,26.19c4.44,7.61,10.68,13.66,18.52,17.97,7.81,4.3,16.82,6.48,26.8,6.48,8.84,0,17.73-2.48,25.59-6.44,5-2.52,11.72-6.28,11.37-12.74-.16-2.94-1.57-5.6-4.23-7.95-2.11-1.78-4.58-2.63-7.42-2.56-4.26.09-7.41,3.12-11.1,4.85-10.79,5.07-24.26,4.67-33.84-2.94-2.68-2.12-4.92-4.79-6.63-7.74-1.07-1.84-1.92-3.8-2.54-5.88h60.96c3.14,0,5.8-1.03,7.9-3.05,2.11-2.03,3.22-4.7,3.22-7.74,0-9.46-1.81-17.97-5.38-25.29-3.62-7.43-8.96-13.35-15.85-17.6Zm-48.9,29c2.14-4.43,5.18-7.82,9.3-10.36,4.08-2.52,9.11-3.79,14.97-3.79,5.03,0,9.32,1.14,12.75,3.4,3.52,2.32,6.17,5.39,8.09,9.38.88,1.83,1.57,3.78,2.08,5.85h-48.92c.47-1.56,1.05-3.05,1.74-4.48Z"
      />
      <path
        className="cls-1"
        d="m475.81,158.04c-2.18-2.45-4.87-3.51-8.11-3.32-2,.11-4.16.28-5.83-.83-.92-.61-1.57-1.56-2.07-2.55-2.23-4.4-1.71-9.47-1.73-14.23-.03-5.59.18-11.17.18-16.75V49.88c0-3.39-1.13-6.23-3.35-8.46-2.22-2.22-5.07-3.35-8.46-3.35s-6.24,1.13-8.46,3.35c-2.22,2.22-3.35,5.07-3.35,8.46v46.76c0,12.2-.08,24.41-.04,36.61.03,9.41-.43,18.97,3.58,27.73,2.4,5.24,5.81,9.45,10.15,12.51,4.42,3.12,9.54,4.7,15.22,4.7h.34c4.12,0,7.59-1.02,10.32-3.03,2.99-2.21,4.58-5.24,4.58-8.78,0-3.32-1-6.12-2.97-8.33Z"
      />
      <path
        className="cls-1"
        d="m304.98,123.47c-5.22-2.76-12.34-5.05-21.14-6.81-5.93-1.21-10.45-2.44-13.44-3.67-3.35-1.38-4.53-2.61-4.93-3.22-.73-1.12-1.09-2.35-1.09-3.77s.39-3.12,3.4-4.6c3.01-1.48,6.81-2.23,11.31-2.23,5.07,0,10.4.72,14.81,3.41,3.89,2.37,6.94,5.95,11.63,6.8,3.37.62,6.38.04,8.93-1.72l.11-.08c2.58-1.94,4.04-4.52,4.21-7.47.16-2.85-.84-5.55-2.98-8.01-8.57-9.87-21.58-14.29-34.37-14.67-6.71-.19-13.5.77-19.84,3-5.86,2.06-10.74,5.24-14.52,9.45-3.96,4.42-5.96,10.02-5.96,16.63,0,7.57,2.99,14.03,8.89,19.21,5.68,5,14.38,8.6,26.64,11.02,10.4,1.87,15,4.06,17.02,5.56,3.3,2.46,3.62,4.7,3.57,6.17-.05,1.46-.5,3.64-3.85,5.72-2.94,1.83-7.45,2.76-13.42,2.76-5.61,0-11.14-1.52-15.97-4.37-4.25-2.5-7.88-6.84-12.95-7.5-3.12-.4-6.22.61-9.15,2.95-2.65,2.03-4.2,4.7-4.59,7.92-.72,5.91,3.56,10.06,8.1,13.05,10.43,6.86,22.85,9.85,35.24,9.85,8.54,0,15.82-1.37,21.64-4.06,5.94-2.75,10.54-6.54,13.66-11.25,3.14-4.74,4.73-10.12,4.73-16,0-5.15-1.26-9.79-3.74-13.78-2.47-3.96-6.48-7.42-11.93-10.31Z"
      />
      <path
        className="cls-1"
        d="m197.9,122.22l29.13-26c2.34-2.18,3.6-4.76,3.77-7.68.16-2.94-.87-5.64-3.05-8-2.04-2.33-4.68-3.65-7.64-3.81-3.02-.16-5.75.88-8.07,3.05l-36.14,32.26v-62.33c0-3.39-1.1-6.21-3.26-8.38-2.17-2.16-4.98-3.26-8.38-3.26s-6.21,1.1-8.38,3.26c-2.17,2.17-3.26,4.98-3.26,8.38v116.84c0,3.39,1.1,6.21,3.26,8.38,2.17,2.16,4.98,3.26,8.38,3.26s6.21-1.1,8.38-3.26c2.17-2.17,3.26-4.98,3.26-8.38v-24.68l4.87-4.35,30.39,37.58c2.03,2.5,4.74,3.95,7.84,4.19.32.02.64.04.95.04,2.7,0,5.2-.91,7.43-2.72l.02-.02c2.64-2.17,4.1-4.8,4.34-7.81.24-2.96-.66-5.72-2.69-8.22l-31.14-38.33Z"
      />
      <path
        className="cls-1"
        d="m132.16,102.3c-4.43-7.64-10.49-13.76-18.02-18.19-7.55-4.44-16.16-6.69-25.6-6.69s-18.08,2.25-25.68,6.69c-7.59,4.43-13.68,10.56-18.11,18.2-4.43,7.65-6.68,16.37-6.68,25.93s2.06,18.18,6.12,25.74c4.07,7.6,9.72,13.71,16.79,18.15,7.11,4.48,15.23,6.75,24.15,6.75s17.04-2.27,24.14-6.74c2.01-1.27,3.91-2.67,5.7-4.21.3,2.73,1.43,5.08,3.35,7,2.28,2.28,5.15,3.44,8.55,3.44s6.27-1.16,8.55-3.43c2.28-2.28,3.44-5.16,3.44-8.55v-40.29l-.04-.02c-.31-8.71-2.55-16.69-6.64-23.77Zm-29.75,50.95c-4.02,2.46-8.68,3.71-13.86,3.71s-9.66-1.22-13.8-3.72c-4.11-2.48-7.4-5.93-9.78-10.25-2.4-4.33-3.61-9.3-3.61-14.76s1.22-10.6,3.61-14.93c2.38-4.32,5.68-7.76,9.78-10.25,4.14-2.5,8.65-3.72,13.8-3.72s9.84,1.25,13.86,3.71c4.05,2.48,7.28,5.92,9.61,10.23,2.35,4.35,3.54,9.38,3.54,14.96s-1.19,10.44-3.54,14.79c-2.34,4.31-5.57,7.76-9.61,10.23Z"
      />
    </svg>
  );
};