import { useToken } from '@ant-design/pro-components';
import { useCreate, useGo } from '@refinedev/core';
import { Alert, Button, Card, Layout, Result, Space, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import { ForgotPasswordForm } from '../../components/auth/forgot-password';
import { LoadingComponent } from '../../components/shared/loading';
import { useIsMobile } from '../../hooks';
import { useDocumentTitle } from '@refinedev/react-router-v6';

const NOT_FOUND_HTTP_STATUS_ERROR = 404;

export const ForgotPasswordPage = () => {
  useDocumentTitle({ i18nKey: 'page.auth.forgotPassword.title' })

  const { t } = useTranslation();
  const { token } = useToken();
  const go = useGo();

  const {
    mutateAsync,
    isLoading: isLoadingCreate,
    isSuccess: isSubmitted,
    isError,
    error,
  } = useCreate();

  const { isMobileWithTablet } = useIsMobile();

  const titleLevel = isMobileWithTablet ? 2 : 1;

  const isSuccess =
    isSubmitted || error?.statusCode === NOT_FOUND_HTTP_STATUS_ERROR;

  return isSuccess ? (
    <Result
      style={{ maxWidth: '660px', margin: '0 auto' }}
      status="success"
      title={t('page.auth.forgotPassword.successMessage')}
      subTitle={t('page.auth.forgotPassword.successSubMessage')}
      extra={[
        <Button key="login" type="primary" href="/auth">
          {t('page.auth.forgotPassword.backToLogin')}
        </Button>,
      ]}
    />
  ) : (
    <Layout
      style={{
        minHeight: isMobileWithTablet ? '100vh' : 'initial',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: token.colorBgContainer,
      }}
    >
      {isLoadingCreate ? (
        <LoadingComponent />
      ) : (
        <Content style={{ padding: isMobileWithTablet ? 0 : '16px', maxWidth: '600px', width: '100%' }}>
          {isError ? (
            <Space direction="vertical" size="small">
              <Alert
                message={t('page.auth.forgotPassword.failed')}
                type="error"
                showIcon
              />
              <Button
                type="primary"
                size="large"
                block
                onClick={() => go({ to: '/auth' })}
              >
                {t('page.confirmEmail.returnToHomepage')}
              </Button>
            </Space>
          ) : (
            <Card
              bordered={false}
              style={{
                borderRadius: '8px',
                boxShadow: token.boxShadowTertiary,
                padding: isMobileWithTablet ? 0 : '24px',
                textAlign: 'center',
              }}
            >
              <Space direction="vertical" size="small">
                <Typography.Title level={titleLevel}>
                  {t('page.auth.forgotPassword.title')}
                </Typography.Title>
                <Typography.Paragraph>
                  {t('page.auth.forgotPassword.description')}
                </Typography.Paragraph>
              </Space>
              <ForgotPasswordForm mutateAsync={mutateAsync} />
            </Card>
          )}
        </Content>
      )}
    </Layout>
  );
};
