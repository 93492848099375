import { CustomParams, CustomResponse, DataProvider, BaseRecord } from '@refinedev/core';
import { axiosInstance } from "./axios";
import { AxiosResponse } from "axios";
import { BaseError, ValidationError } from "./axios/types";
import { Paginated } from "./dto/utils";
import queryString from 'query-string';
import { createSingleFilterQuery}  from "./utils";

const DEVELOPMENT_API_URL = 'http://localhost:3000/api/platform/v1';
const PROD_API_URL = 'https://api.garage.aksel.nu/api/platform/v1';
const IS_PRODUCTION_MODE = import.meta.env.PROD;

export const API_URL = IS_PRODUCTION_MODE  ? PROD_API_URL : DEVELOPMENT_API_URL;

type CustomDataProvider = DataProvider & {
  custom: <
      TData extends BaseRecord = BaseRecord,
      TQuery = unknown,
      TPayload = unknown,
  >(
      params: CustomParams<TQuery, TPayload>,
  ) => Promise<CustomResponse<TData>>;
}

export const dataProvider: CustomDataProvider = {
  getList: async function({ resource, pagination, sorters, filters, meta}) {
    const url = `${API_URL}/${resource}`;

    const { current, pageSize} = pagination ?? {};

    const sortBy = sorters?.map(item => `${item.field}:${item.order.toUpperCase()}`);

    const filter = filters?.flatMap((item) => createSingleFilterQuery(item));

    const query = {
      page: current,
      limit: pageSize,
      sortBy,
      select: meta?.select,
      searchBy: meta?.searchBy,
      search: meta?.search,
    };

    let queryReqString = queryString.stringify(query);
    if (filter) {
      queryReqString = queryReqString.concat('&', ...filter.map(f => `${f}`))
    }

    const { data }: AxiosResponse<Paginated<any>, ValidationError | BaseError> = await axiosInstance.get(
        `${url}?${queryReqString}`,
    );

    return {
      data: data.data,
      total: data.meta.totalItems,
      meta: data.meta,
      links: data.links,
    };
  },
  getOne: async function({ id, resource }) {
    const url = `${API_URL}/${resource}/${id}`;

    const { data } = await axiosInstance.get(url);

    return {
      data,
    };
  },
  update: async function({ resource, id, variables }) {
    const url = `${API_URL}/${resource}/${id}`;

    const { data } = await axiosInstance.put(url, variables);

    return {
      data,
    };
  },
  create: async function({ resource, variables }) {
    const url = `${API_URL}/${resource}`;

    const { data } = await axiosInstance.post(url, variables);

    return {
      data,
    };
  },
  deleteOne: async function({ resource, id, variables }) {
    const url = `${API_URL}/${resource}/${id}`;

    const { data } = await axiosInstance.delete(url, {
      data: variables,
    });

    return {
      data,
    };
  },
  getApiUrl: function() {
    return API_URL;
  },
  custom: async ({ url, method, payload, query }) => {
    let requestUrl = `${API_URL}/${url}`;

    if (query) {
      requestUrl = `${requestUrl}?${queryString.stringify(query)}`;
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await axiosInstance[method](requestUrl, payload);
        break;
      case "delete":
        axiosResponse = await axiosInstance.delete(requestUrl, {
          data: payload,
        });
        break;
      default:
        axiosResponse = await axiosInstance.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
};