import { UserDto } from '../../provider/auth/vo/user.dto';
import { InitialStateType } from './vo';

export enum ActionTypes {
  INITIALIZE = 'INITIALIZE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type AuthPayload = {
  isAuthenticated?: boolean;
  user?: UserDto | null;
  type?: string;
};

export type ActionType<T = AuthPayload> = {
  type: ActionTypes;
  payload?: T;
};

type HandlerType<S, P> = (state: S, action: ActionType<P>) => S;

const handlers: Record<ActionTypes, HandlerType<InitialStateType, any>> = {
  [ActionTypes.INITIALIZE]: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  [ActionTypes.LOGIN]: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  [ActionTypes.LOGOUT]: (state: InitialStateType) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

export const authReducer = <P>(
  state: InitialStateType,
  action: ActionType<P>,
): InitialStateType =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
