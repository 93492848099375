import { Divider, Flex, Space, Typography } from 'antd';
import { UploadImagesCard } from '../image-card';
import detail1 from '../../../../assets/images/picture-guide/detaljer-correct1.webp';
import detail2 from '../../../../assets/images/picture-guide/detaljer-correct2.webp';
import detail3 from '../../../../assets/images/picture-guide/detaljer-correct3.webp';
import detail4 from '../../../../assets/images/picture-guide/detaljer-correct4.webp';
import detail5 from '../../../../assets/images/picture-guide/detaljer-correct5.webp';
import detail6 from '../../../../assets/images/picture-guide/detaljer-correct6.webp';
import detail7 from '../../../../assets/images/picture-guide/detaljer-correct7.webp';
import { ImagesFileUploader, ImagesFileUploaderProps } from '../images-file-uploader';
import { List } from '../list/list';

const { Title } = Typography;
const images = [detail1, detail2, detail3, detail4, detail5, detail6, detail7];

const advicePoints = [
  'Hvis din bil har særlige detaljer såsom emblemer, ratbetjening, USB-udtag, indstigningslister og lignende, så tag et nærbillede af det.',
  'Tag også gerne billede af kilometertæller samt serienummer og versionsinformation',
  'Sørg for at tage billedet tæt på, så man kan se, hvad det er.'
]

export const CarDetailsGuide = ({
  dealId,
  formRef,
  fileUploader,
  isLoading,
}: ImagesFileUploaderProps) => {
  return (
    <Flex vertical>
      <Flex justify="center">
        <Title level={3}>Detaljer</Title>
      </Flex>
      <Space direction="vertical" size={'large'}>
        <List items={advicePoints} />
        <Flex gap={20} vertical align="center">
          <Space size={'middle'} style={{ justifyContent: 'center' }} wrap>
            {images.map((image, index) => (
              <UploadImagesCard
                key={index}
                imageSrc={image}
                color="green"
              />
            ))}
          </Space>
          <ImagesFileUploader
            name='details'
            formRef={formRef}
            dealId={dealId}
            autoSave={true}
            fileUploader={fileUploader}
            isLoading={isLoading}
            required={false}
          />
          <Divider />
        </Flex>
      </Space>
    </Flex>
  );
};
