import { decodeToken } from 'react-jwt';
import { ActionType, ActionTypes } from '../../../contexts/auth';
import { omit } from 'lodash';
import { axiosInstance } from '../../axios';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../constants';
import { UserDto } from '../vo/user.dto';
import { DecodedToken } from '../vo/decoded-token.interface';

export const saveAuthDataAndDispatchLogin = (
  accessToken: string,
  refreshToken: string,
  dispatch: React.Dispatch<ActionType>,
) => {
  setAuthSession(accessToken);
  setRefreshToken(refreshToken);

  const user = getUserFromSession(accessToken);

  dispatch({
    type: ActionTypes.LOGIN,
    payload: {
      user,
    },
  });
  return user;
};

export const getUserFromSession = (accessToken: string) => {
  const user = decodeToken<UserDto>(accessToken) || {};
  return omit(user) as unknown as UserDto;
};

export const setAuthSession = (token: string | null) => {
  if (token) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);

    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export const setRefreshToken = (refreshToken: string | null) => {
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

    axiosInstance.defaults.headers.common[REFRESH_TOKEN_KEY] = refreshToken;
  } else {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    delete axiosInstance.defaults.headers.common[REFRESH_TOKEN_KEY];
  }
};

export const isValidToken = (token: string, threshold: number = 0) => {
  if (!token) {
    return false;
  }

  const decoded = decodeToken<DecodedToken>(token);
  if (!decoded || typeof decoded.exp !== 'number') {
    return false;
  }

  const currentTime = Math.floor(Date.now() / 1000);

  return decoded.exp > (currentTime + threshold);
};