import { ListItem } from './list-item';

export const List = ({ items }: { items: string[] }) => (
  <ul>
    {items.map((item, index) => (
      <ListItem key={`${index}-${item}`}>
        {item}
      </ListItem>
    ))}
  </ul>
);