
export interface FileUpload {
  originalName: string;
  urlKey: string;
  size: number;
  type: string;
}

export enum DealStatus {
  UNDER_REVIEW = 'UNDER_REVIEW',
  LEAD_REJECTED = 'LEAD_REJECTED',
  WAITING_PRICE_ACCEPTANCE = 'WAITING_PRICE_ACCEPTANCE',
  WAITING_FOR_IMAGES = 'WAITING_FOR_IMAGES',
  ADVERTISEMENT_CREATED = 'ADVERTISEMENT_CREATED',
  ADVERTISEMENT_LIVE = 'ADVERTISEMENT_LIVE',
  SHOWING_TO_BUYER = 'SHOWING_TO_BUYER',
  SELL_AGREED = 'SELL_AGREED',
  SOLD = 'SOLD',
  INACTIVE = 'INACTIVE',
}

export function getStatusOrder(status?: DealStatus) {
  switch (status) {
    case DealStatus.UNDER_REVIEW:
      return 1;
    case DealStatus.WAITING_PRICE_ACCEPTANCE:
      return 2;
    case DealStatus.WAITING_FOR_IMAGES:
      return 3;
    case DealStatus.ADVERTISEMENT_CREATED:
      return 4;
    case DealStatus.ADVERTISEMENT_LIVE:
      return 5;
    case DealStatus.SHOWING_TO_BUYER:
      return 6;
    case DealStatus.SELL_AGREED:
      return 7;
    case DealStatus.SOLD:
      return 8;
    case DealStatus.INACTIVE:
      return -1;
    default:
      return 0;
  }
}

export interface Submission {
  id: string
  vin: string
  make: string
  model: string
  mileage: number
  year: number
  color: string
  gear: string
  askingPrice: number
  status: DealStatus
  fuel: string
  organisationId: string
  images: FileUpload[]
  version: number
  createdAt: string
  updatedAt: string
  deletedAt?: string
}
