import { HttpError } from '@refinedev/core';
import { AxiosError } from 'axios';

type ErrorResponseDataType = {
  detail?: string;
  title?: string;
};

type AxiosCustomError = AxiosError<ErrorResponseDataType> &
  HttpError & {
    isAxiosError: boolean;
  };

export const isAxiosError = (error: any): error is AxiosCustomError => {
  return error && 'isAxiosError' in error;
};
