import { Flex, FlexProps, Spin } from 'antd';
import { SpinProps } from 'antd/lib';

export const LoadingComponent = ({
  justify = 'center',
  size = 'large',
}: {
  justify?: FlexProps['justify'];
  size?: SpinProps['size'];
}) => {
  return (
    <Flex justify={justify}>
      <Spin size={size} />
    </Flex>
  );
};
