/* eslint-disable @typescript-eslint/no-explicit-any */
import { ParsedParams, useCreate } from '@refinedev/core';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApprovalEmailParamsData } from '../../../types/auth';
import { isValidUUIDv4 } from '../../../utils/validation/is-valid-uuid-v4';
import { ParamsType } from '@ant-design/pro-components';
import {
  ResourceType,
  UseValidateEmailParamsAndMutateOptions,
} from './vo';
import { MutateAsyncType } from '../../../types/react-query';

const CONFIRMATION_CODE_PATTERN = /^\d{6}$/;

const validateParams = (params: unknown): params is ParamsType => {
  if (typeof params !== 'object' || params === null) {
    return false;
  }

  const { id, code } = params as ParamsType;

  const isValidUUID = isValidUUIDv4(id);
  const isValidCode = CONFIRMATION_CODE_PATTERN.test(code);
  return isValidUUID && isValidCode;
};

const triggerDataMutation = async (
  mutateAsync: MutateAsyncType,
  resource: ResourceType,
  values: any,
) => {
  await mutateAsync({
    successNotification: () => false,
    resource: `auth/${resource}`,
    values,
  });
};

export const useValidateEmailParamsAndMutate = (
  params: ParsedParams<ApprovalEmailParamsData> | undefined,
  options: UseValidateEmailParamsAndMutateOptions,
) => {
  const navigate = useNavigate();
  const [isIncorrectParams, setIncorrectParams] = useState(false);
  const { mutateAsync, isLoading, isSuccess, isError } = useCreate();

  const validationNotSkipped = options.validate ?? true;
  const areParamsValid = validationNotSkipped ? validateParams(params) : true;

  useEffect(() => {
    const areParamsMissed = !params?.id && !params?.code;
    if (validationNotSkipped && areParamsMissed) {
      return navigate('/404');
    }
    if (!areParamsValid) {
      setIncorrectParams(true);
    }
  }, []);

  const refetch = useCallback(
    async (values: any) => {
      if (validateParams(values)) {
        try {
          await triggerDataMutation(mutateAsync, options.resource, values);
          setIncorrectParams(false);
        } catch {
          setIncorrectParams(true);
        }
      } else {
        setIncorrectParams(true);
      }
    },
    [mutateAsync, options.resource],
  );

  useEffect(() => {
    if (options.enabled && areParamsValid) {
      refetch(params);
    }
  }, [params, refetch, options.enabled, areParamsValid]);

  return {
    isIncorrectParams,
    isLoading,
    isSuccess,
    isError,
    refetch,
  };
};
