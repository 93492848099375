import { CheckCard, ProFormText } from '@ant-design/pro-components';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { YesNoDontKnowOptionSelector } from '../../../yes-or-no';
import { useIsMobile } from '../../../../hooks';

export const CarRepaintQuestionForm = () => {
  const { t } = useTranslation();
  const { isMobileWithTablet } = useIsMobile();

  return (
    <>
      <Form.Item
        label={t(
          'page.dealDetails.uploadImagesForm.questions.repainted.question',
        )}
        name="repainted"
        rules={[{ required: true, message: t('global.requiredField') }]}
      >
        <CheckCard.Group style={{ width: '100%' }} size="small">
          <YesNoDontKnowOptionSelector
            fullWidth
            vertical={isMobileWithTablet}
            includeDontKnowOption
            justify="flex-start"
          />
        </CheckCard.Group>
      </Form.Item>

      <Form.Item
        style={{ paddingLeft: '24px' }}
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.repainted !== currentValues.repainted
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('repainted') === true ? (
            <ProFormText
              name="repaintedAnswer"
              label={t(
                'page.dealDetails.uploadImagesForm.questions.repainted.followup',
              )}
              placeholder={t(
                'page.dealDetails.uploadImagesForm.questions.repainted.placeholder',
              )}
              rules={[
                {
                  required: true,
                  message: t('global.requiredField'),
                },
              ]}
              formItemProps={{ style: { width: '100% ' } }}
            />
          ) : null
        }
      </Form.Item>
    </>
  );
};
