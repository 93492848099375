export enum RoleType {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  REGULAR_USER = 'REGULAR_USER',
}

export interface RoleInfo<T> {
    roleId: string;
    roleType?: T;
}

export type UserProfileType = {
	email: string;
	phoneNumber: string;
	zipCode: string;
	firstName: string;
	lastName: string;
	city: string;
	address: string;
}