import { Flex } from 'antd';
import { ChipTuningQuestionForm } from './car-questionnaire/chip-tuned-question.form';
import { CarDamageQuestionForm } from './car-questionnaire/damage-question.form';
import { EngineQuestionForm } from './car-questionnaire/engine-question.form';
import { CarRepaintQuestionForm } from './car-questionnaire/repaint-question.form';
import { ServiceDocumentationQuestionForm } from './car-questionnaire/service-documentation-question.form';
import { TimingBeltChangeQuestionForm } from './car-questionnaire/timing-belt-question.form';
import { WhatTiresComeWithCarQuestionForm } from './car-questionnaire/tires.question.form';

export const CarQuestionnaire = () => {
  return (
    <>
      <Flex vertical>
        <EngineQuestionForm  />
        <CarRepaintQuestionForm />
        <CarDamageQuestionForm />
        <ChipTuningQuestionForm />
        <TimingBeltChangeQuestionForm />
        <ServiceDocumentationQuestionForm />
        <WhatTiresComeWithCarQuestionForm />
      </Flex>
    </>
  );
};
