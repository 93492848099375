import { FileUpload } from '../provider/dto/file-upload.dto';
import { DealStatus } from '../pages/partner/vo/submission';
import { CarUsageDetails } from '../components/deal-details/upload-images/vo';

export enum Fuel {
  GAS = 'GAS',
  DIESEL = 'DIESEL',
  ELECTRIC = 'ELECTRIC',
  HYBRID = 'HYBRID',
  FGAS = 'FGAS',
  PETROLEUM = 'PETROLEUM',
  NITROGEN = 'NITROGEN',
  HYDROGEN = 'HYDROGEN',
  ETHANOL = 'ETHANOL',
}

export enum Gear {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
}

export function isElectric(fuel?: string): boolean {
  return fuel === Fuel.ELECTRIC || fuel === Fuel.HYBRID;
}

export interface Deal {
  id: string
  licensePlate: string
  make: string
  model: string
  variant: string
  year: number
  registration: string
  getPipedriveDealId: number
  getSellerName: string
  getCaradsId: number
  getSellerEmail: string
  lastInspectionDate: string
  mileage: number
  fuel: Fuel
  type: string
  color: string
  preferredShowingTimeWeekday?: string[]
  preferredShowingTimeWeekend?: string[]
  damaged: boolean
  debt: boolean
  images: FileUpload[]
  usageDetails: CarUsageDetails
  organisationId: string
  damagedDetails: string
  status: DealStatus
  vin: string
  equipmentFreeText: string
  askingPrice?: number
  sellerValuation: number
  condition: string
  gear: Gear
  sellerId: string
  buyerId: string
  version: number
  createdAt: string
  updatedAt: string
  deletedAt?: string
}
