import { Locale } from 'antd/es/locale';
import da_DK from 'antd/locale/da_DK';

export const danishLocale = {
  ...da_DK,
  DatePicker: {
    ...da_DK.DatePicker,
    lang: {
      ...da_DK.DatePicker?.lang,
      shortMonths: [
        'Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun',
        'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'
      ],
      locale: 'da',
    },
  },
} as Locale;