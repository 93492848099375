import { UserProfileType } from '../../../types/user';
import { axiosInstance } from '../../axios';
import { API_URL } from '../../data-provider';
import { RefreshTokenResponse } from '../vo/refresh-token-response.interface';
import { SignInResponse } from '../vo/signin-response.interface';

export const signIn = async (email: string, password: string) => {
  const response = await axiosInstance.post<SignInResponse>(
    `${API_URL}/auth/signin`,
    { email, password },
  );
  return response.data;
};

export const refreshToken = async () => {
  const response = await axiosInstance.post<RefreshTokenResponse>(
    `${API_URL}/auth/refresh-access-token`,
  );
  return response.data;
};

export const signUp = async (data: UserProfileType) => {
  const response = await axiosInstance.post<SignInResponse>(
    `${API_URL}/auth/signup`,
    data,
  );
  return response.data;
};