import { AnswerType, MotorChangedAnswerType } from '../vo';

interface MotorQuestionResultYes {
  answer: AnswerType.YES;
  details: MotorChangedAnswerType;
}

interface MotorQuestionResultNoOrNotSure {
  answer: Exclude<AnswerType, AnswerType.YES>;
  details?: never;
}

type MotorQuestionResult =
  | MotorQuestionResultYes
  | MotorQuestionResultNoOrNotSure;

export const getMotorQuestionResult = (
  answer: AnswerType,
  details?: MotorChangedAnswerType,
): MotorQuestionResult => {
  if (answer === AnswerType.YES && details) {
    return { answer, details };
  }

  if (answer === AnswerType.NO || answer === AnswerType.NOT_SURE) {
    return { answer };
  }

  throw new Error('getMotorQuestionResult: Not Mapped, contact developers');
};