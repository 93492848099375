import {
  ProFormCheckbox, ProFormDigit,
  ProFormText,
  useToken,
} from '@ant-design/pro-components';
import { Button, Divider, Flex, Form, Space, Typography } from 'antd';
import { FormInstance } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { MutateAsyncType } from '../../types/react-query';
import { UserProfileType } from '../../types/user';

type AcceptPriceFormProps = {
  acceptPriceMutate: MutateAsyncType;
  dealId?: string;
  existingUserInfo?: UserProfileType;
  isLoadingCreate: boolean;
  form: FormInstance<any>;
};

const { Title, Text, Link } = Typography;

export const AcceptPriceGeneralForm = ({
  acceptPriceMutate,
  dealId,
  existingUserInfo,
  isLoadingCreate,
  form,
}: AcceptPriceFormProps) => {
  const { t } = useTranslation();
  const { token } = useToken();

  const onFinishAccept = async (values: any) => {
    if (!dealId) return;

    await acceptPriceMutate({
      successNotification: () => false,
      resource: `deal/${dealId}/accept-price-and-register`,
      values: {
        carViewingAddress: {
          address: values.address,
          city: values.city,
          zipCode: Number(values.zipCode),
        },
        preferredShowingTimeWeekend: values.preferredShowingTimeWeekendDefault,
        preferredShowingTimeWeekday: values.preferredShowingTimeWeekdayDefault,
      },
      errorNotification: false,
    });
  };

  return (
    <Form
      form={form}
      name="acceptPrice"
      onFinish={onFinishAccept}
      layout="vertical"
      style={{ width: '100%' }}
      initialValues={{
        ...existingUserInfo,
        zipCode: Number(existingUserInfo?.zipCode) || ''
    }}
    >
      <Flex vertical align="center">
        <Title style={{ fontWeight: '500' }} level={4}>
          Hvor står bilen?
        </Title>
        <Flex
          wrap
          style={{
            maxWidth: '820px',
            width: '100%',
            justifyContent: 'center',
            columnGap: '20px',
          }}
        >
          <ProFormText
            name="address"
            label={t('common.user.address.label')}
            placeholder={t('common.user.address.placeholder')}
            rules={[
              {
                required: true,
                message: t('global.requiredField'),
              },
            ]}
            formItemProps={{ style: { width: '100%' } }}
          />
          <ProFormText
            name="city"
            label={t('common.user.addressCity.label')}
            placeholder={t('common.user.addressCity.placeholder')}
            rules={[
              {
                required: true,
                message: t('global.requiredField'),
              },
            ]}
            formItemProps={{ style: { width: '100%' } }}
          />
          <ProFormDigit
            min={0}
            max={9999}
            fieldProps={{ precision: 0 }}
            name="zipCode"
            label={t('common.user.zipCode.label')}
            placeholder={t('common.user.zipCode.placeholder')}
            rules={[
              {
                required: true,
                message: t('global.requiredField'),
              },
            ]}
            formItemProps={{ style: { width: '100% ' } }}
          />
        </Flex>
      </Flex>
      <Divider />

      <Flex vertical>
        <Title level={2}>Foretrukne tider til fremvisning</Title>
        <Space direction="vertical" size="middle">
          <Text>
            Når vi har fundet en potentiel køber, vil langt de fleste gerne ud
            og se bilen.
          </Text>
          <Text>Købere ønsker som regel en fremvisning inden for et døgn.</Text>
          <Text>
            Så for at gøre processen så nem som mulig for både dig og køber, vil
            vi gerne vide, hvornår du typisk vil kunne fremvise bilen i hhv.
            hverdage og weekender.{' '}
          </Text>
          <Text>
            Vi laver derudfra en forhåndsaftale med køber om en fremvisning, og
            kontakter dig, for at få den bekræftet, eller finde et alternativt
            tidspunkt, hvis du ikke kan.
          </Text>{' '}
        </Space>
        <ProFormText
          label={t('global.timeRangePicker.labelWeekday')}
            name="preferredShowingTimeWeekdayDefault"
            rules={[
              {
                required: true,
                message: t('global.requiredField'),
              },
            ]}
          placeholder={t('global.timeRangePicker.labelWeekdayPlaceholder')}
        />
 <ProFormText
          label={t('global.timeRangePicker.labelWeekend')}
            name="preferredShowingTimeWeekendDefault"
            rules={[
              {
                required: true,
                message: t('global.requiredField'),
              },
            ]}
          placeholder={t('global.timeRangePicker.labelWeekdayPlaceholder')}
        />
        <ProFormCheckbox
          formItemProps={{ style: { flexDirection: 'row' } }}
          name="acceptTradingConditions"
          rules={[
            {
              required: true,
              message: t('global.requiredField'),
            },
          ]}
        >
          <Text>
            Jeg accepterer Aksel.nu’s{' '}
            <Link target={'_blank'} href="http://aksel.nu/handelsbetingelser/">
              handelsebetingelser
            </Link>{' '}
            heriblandt at bilen ikke annonceres andre steder så længe den er til
            salg gennem Aksel.nu
          </Text>
          <span style={{ color: token.colorError }}> *</span>
        </ProFormCheckbox>
        <Flex justify="center">
          <Button loading={isLoadingCreate} type="primary" htmlType="submit">
            {t('page.acceptPrice.submit')}
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};
