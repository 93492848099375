import { SEASONS } from '../car-questionnaire/tires.question.form';

export enum AnswerType {
  YES = 'YES',
  NO = 'NO',
  NOT_SURE = 'NOT_SURE',
}

export enum ServiceInTimeType {
  YES = 'YES',
  PARTIALLY = 'PARTIALLY',
  NO_INFO = 'NO_INFO',
}

export enum MotorChangedAnswerType {
  NEW = 'NEW',
  USED = 'USED',
  RENOVATED = 'RENOVATED',
}

export type MotorChangedAnswer = {
  answer: string;
  details?: string;
};

export type RepaintedAnswer = {
  answer: string;
  details?: string;
};

export type DamagedAnswer = {
  answer: string;
  details?: string;
};

export type ChipTunedAnswer = {
  answer: string;
  details?: boolean;
};

export type TimeBeltChangedAnswer = {
  answer: string;
  details?: number;
};

export type CarUsageDetails = {
  motor: MotorChangedAnswer;
  repainted: RepaintedAnswer;
  damaged: DamagedAnswer;
  chipTuned: ChipTunedAnswer;
  timeBeltChanged: TimeBeltChangedAnswer;
  serviceInTime: string;
  tires: keyof typeof SEASONS;
};
