import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, Image } from 'antd';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import CarThumbnail from '../../../assets/car-thumbnail.svg';

type UploadImagesCardProps = {
  imageSrc: string | React.ReactNode;
  color?: 'green' | 'red';
  noMark?: boolean;
  preview?: boolean;
  bordered?: boolean;
  isSquareShape?: boolean;
  cardStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  placeholder?: React.ReactNode;
};

const DEFAULT_IMAGE_WIDTH = '240px';
const RED_COLOR_HEX = '#cc0000';
const GREEN_COLOR_HEX = '#34b233';

const cardSquaredStyles = {
  height: DEFAULT_IMAGE_WIDTH,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
} satisfies CSSProperties;

const imageDefaultStyles: CSSProperties = {
  width: '100%',
  height: '100%',
};
const imageSquaredStyles: CSSProperties = {
  ...imageDefaultStyles,
  objectFit: 'cover',
  display: 'block',
};

export const UploadImagesCard = ({
  imageSrc,
  color = 'red',
  cardStyle,
  noMark,
  placeholder,
  preview = false,
  isSquareShape = false,
  imageStyle,
  bordered
}: UploadImagesCardProps) => {
  const renderMark = () => {
    if (noMark) {
      return null;
    }
    if (color === 'green') {
      return <StyledCheckOutlined color={GREEN_COLOR_HEX} />;
    } else {
      return <StyledCloseOutlined color={RED_COLOR_HEX} />;
    }
  };

  const cardStyles = isSquareShape
    ? { ...cardSquaredStyles, ...cardStyle }
    : { ...cardStyle };

  const imageStyles = isSquareShape ? imageSquaredStyles : imageDefaultStyles;

  return (
    <Card
      style={{ width: DEFAULT_IMAGE_WIDTH, border: bordered ? '1px solid #f0f0f0' : 'none', ...cardStyles }}
      styles={{ body: { padding: 0 } }}
    >
      {typeof imageSrc === 'string' ? (
        <Image
          preview={preview}
          style={{ ...imageStyles, ...imageStyle }}
          src={imageSrc}
          placeholder={placeholder}
        />
      ) : (
        imageSrc
      )}
      {renderMark()}
    </Card>
  );
};

const StyledCheckOutlined = styled(CheckOutlined)<{ color: string }>`
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 48px;
  color: ${props => props.color};
  z-index: 1;
`;

const StyledCloseOutlined = styled(CloseOutlined)<{ color: string }>`
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 48px;
  color: ${props => props.color};
  z-index: 1;
`;
