import { Divider, Flex, Space, Typography } from 'antd';
import { UploadImagesCard } from '../image-card';
import { List } from '../list/list';
import dashboard1 from '../../../../assets/images/picture-guide/instrumentbraet-incorrect1.webp';
import dashboardOk from '../../../../assets/images/picture-guide/instrumentbraet-correct.webp';
import dashboard2 from '../../../../assets/images/picture-guide/instrumentbraet-incorrect2x.webp';
import {
  ImagesFileUploader,
  ImagesFileUploaderProps,
} from '../images-file-uploader';

const { Title } = Typography;

const advicePoints = [
  'Sæt dig i midten på bagsædet.',
  'Fokuser på instrumentbræt og konsol – ikke forruden',
  'Sørg for, man ikke kan se ryggen af forsæderne',
];

export const DashboardViewGuide = ({
  dealId,
  formRef,
  fileUploader,
  isLoading,
}: ImagesFileUploaderProps) => {
  return (
    <Flex vertical>
      <Flex justify="center">
        <Title level={3}>Instrumentbræt</Title>
      </Flex>
      <Space direction="vertical" size={'large'}>
        <List items={advicePoints} />
        <Flex gap={24} wrap justify='center' align="center">
          <UploadImagesCard imageSrc={dashboard1} color="red" />
          <UploadImagesCard imageSrc={dashboard2} color="red" />
          <UploadImagesCard imageSrc={dashboardOk} color="green" />
          <ImagesFileUploader
            name="dashboard"
            formRef={formRef}
            dealId={dealId}
            autoSave={true}
            fileUploader={fileUploader}
            isLoading={isLoading}
          />
          <Divider />
        </Flex>
      </Space>
    </Flex>
  );
};
