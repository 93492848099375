import { UppyFile } from '@uppy/core';

export const flattenAndTransformFiles = (values: Record<string, unknown>) => {
  return Object.entries(values).reduce((acc, [key, files]) => {
    if (Array.isArray(files)) {
      files.forEach((file, index) => {
        const uniqueId = `${key}-${file.id}-${index}`;
        acc[uniqueId] = {
          ...file,
          id: uniqueId,
          meta: { ...file.meta, name: `${key}-${file.meta.name}` },
          name: `${key}-${file.name}-${file.id}`
        };
      });
    }
    
    return acc;
  }, {} as Record<string, UppyFile>);
};