import { useEffect, useReducer, useState } from 'react';
import {
  ActionTypes,
  AuthContext,
  authReducer,
  initialState,
} from '../../contexts/auth';
import { createRefineAuthProvider } from './refine-auth.provider';
import { refreshSessionAndLogin } from './helpers/refresh-session';
import { useTranslation } from 'react-i18next';
import { refreshOrInitializeAuthSession } from './helpers/check-auth';
import { RoleType } from '../../types/user';

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [initialization, setInitialization] = useState(true);

  const { t } = useTranslation();

  const authProvider = createRefineAuthProvider(state, dispatch, t);
  const user = state.user;

  let isAdmin;
  if (!user) {
    isAdmin = false;
  }

  const roles: RoleType[] = (user?.roles ?? [])
    .map(role => role.roleType)
    .filter((role): role is RoleType => role !== undefined);

  isAdmin = roles.includes(RoleType.SUPER_ADMIN);

  useEffect(() => {
    const initialize = async () => {
      try {
        const result = await refreshOrInitializeAuthSession(state, dispatch, t);
        if (!result) {
          dispatch({
            type: ActionTypes.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(
          'Error during authentication session initialization:',
          err,
        );
        dispatch({
          type: ActionTypes.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      } finally {
        setInitialization(false);
      }
    };

    initialize();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        refreshToken: refreshSessionAndLogin,
        user,
        isAdmin,
        userRoles: roles,
        ...authProvider,
      }}
    >
      {initialization ? null : children}
    </AuthContext.Provider>
  );
};
