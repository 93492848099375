import {
  CheckCard,
  ProForm,
  ProFormDatePicker,
  ProFormDigit,
  ProFormInstance,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreate, useGo } from '@refinedev/core';
import { Button, Col, Flex, Form, Row, Typography } from 'antd';
import FileUploader, { IFileUploaderProps } from '../file-uploader';
import { FileUpload } from '../../pages/partner/vo/submission';
import dayjs from 'dayjs';
import { CreatePartnerDeal } from '../../provider/dto/partner-deal.dto';
import { useIsMobile } from '../../hooks';

const { Title } = Typography;

export const PartnerB2bSubmissionForm: React.FC<{
  organisationId: string;
}> = ({
        organisationId,
      }) => {

  const fileUploader = useRef<IFileUploaderProps>(null);

  const formRef = useRef<
    ProFormInstance<CreatePartnerDeal>
  >();

  const { mutateAsync, isLoading } = useCreate();

  const { t } = useTranslation();

  const go = useGo();

  const disabledDate = (current: dayjs.Dayjs | null): boolean => {
    return !!current && current.isAfter(dayjs().endOf('day'));
  };

  const { isMobileWithTablet } = useIsMobile()

  return <ProForm
    formRef={formRef}
    autoFocusFirstInput
    onFinish={async (values) => {

      const uploadResult = await fileUploader.current!.upload();

      const images: FileUpload[] = uploadResult.successful.map((item) => {
        const meta = item.meta;
        const data = item.data;

        return {
          size: data.size,
          originalName: meta.name,
          type: data.type,
          urlKey: String(meta.key),
        }
      })

      await mutateAsync({
        resource: 'partner-deals/' + organisationId,
        values: {
          ...values,
          images,
        },
      });

      go({
        to: '/partner/' + organisationId,
      });

    }}

    submitter={{
      submitButtonProps: false,
      resetButtonProps: false,
      render: () => [],
    }}
    style={{ maxWidth: 820, margin: '0 auto', padding: 24 }}
  >
    <Title level={1}>{t(`page.partnerCarSubmission.title`)}</Title>
    <Title level={3}>{t(`page.partnerCarSubmission.description`)}</Title>

    <ProFormText
      name="vin"
      label={t('common.vin')}
      placeholder={'WBSWD93549PY43XXX'}
      rules={[{ required: true, message: t('global.requiredField') }]}
    />

    <Row
      gutter={[16, 0]}
    >
      <Col xs={24} sm={8}>
        <ProFormText
          name="make"
          label={t('common.carMake')}
          placeholder={t('Tesla')}
          rules={[{ required: true, message: t('global.requiredField') }]}
        />
      </Col>
      <Col xs={24} sm={8}>
        <ProFormText
          name="model"
          label={t('common.carModel')}
          placeholder={t('Model Y')}
          rules={[{ required: true, message: t('global.requiredField') }]}
        />
      </Col>
      <Col xs={24} sm={8}>
        <ProFormText
          name="variant"
          label={t('common.variant')}
          placeholder={t('Performance')}
          rules={[{ required: true, message: t('global.requiredField') }]}
        />
      </Col>
    </Row>

    <ProFormSelect
      fieldProps={
        {
          'notFoundContent': t('common.noDataText'),
        }
      }
      name="year"
      label={t('common.year')}
      showSearch
      placeholder={new Date().getFullYear() + ''}

      options={[...Array(100).keys()].map(v => {
        const year = new Date().getFullYear() - v;

        return {
          value: year,
          label: year + '',
        };
      })}
      rules={[{ required: true, message: t('global.requiredField') }]}
    />

    <ProFormDatePicker
      name="registration"
      label={t('common.registration')}
      placeholder={'1970-01-01'}
      rules={[{ required: true, message: t('global.requiredField') }]}
      fieldProps={{
        disabledDate,
        style: { width: '100%', background: 'white' }
      }}
    />

    <ProFormDigit
      name="mileage"
      min={0}
      label={t('common.mileage')}
      placeholder={'123000'}
      rules={[{ required: true, message: t('global.requiredField') }]}
    />

    <ProFormText
      name="color"
      label={t('common.color')}
      placeholder={'orange'}
      rules={[{ required: true, message: t('global.requiredField') }]}
    />

    <Form.Item
      label={t('common.gear')}
      name="gear"
      rules={[{ required: true, message: t('global.requiredField') }]}
    >
      <CheckCard.Group
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '0 auto',
          maxWidth: '512px',
        }}
      >
        <Row style={{ width: '100%', }} gutter={[16, 0]}>
          {
            Object.entries(t('common.gearTypes', {
              returnObjects: true,
            })).map(([key, value]) => (
              <Col style={{ paddingLeft: isMobileWithTablet ? 0 : 16 }} xs={24} sm={12}>
                <CheckCard
                  style={{
                    width: '100%',
                  }}
                  title={value}
                  value={key}
                />
              </Col>

            ))}
        </Row>

      </CheckCard.Group>
    </Form.Item>

    <Form.Item
      label={t('common.fuel')}
      name="fuel"
      rules={[{ required: true, message: t('global.requiredField') }]}
    >
      <CheckCard.Group
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '0 auto',
          maxWidth: '512px',
        }}
      >
        <Row style={{ width: '100%' }} gutter={[16, 0]}>
          {
            Object.entries(t('common.fuelTypes', {
              returnObjects: true,
            })).map(([key, value]) => (
              <Col style={{ paddingLeft: isMobileWithTablet ? 0 : 16 }} xs={24} sm={12}>
                <CheckCard
                  style={{
                    width: '100%',
                  }}
                  title={value}
                  value={key}
                />
              </Col>

            ))}
        </Row>

      </CheckCard.Group>
    </Form.Item>

    <Form.Item
      name={'images'}
      label={'Uploade bilbilleder'}
      rules={[
        { required: true, message: t('global.requiredField') },
      ]}
    >
      <FileUploader
        ref={fileUploader}
        orgOrDealId={organisationId}
        disabled={isLoading}
        setFilesData={(v) => {
          formRef.current?.setFieldValue('images', v);
          formRef.current?.validateFields(['images']);
        }}
      />
    </Form.Item>


    <Form.Item>
      <Flex justify={'flex-end'}>
        <Button size={'large'} loading={isLoading} htmlType={'submit'} type={'primary'}
                shape={'round'}>{t('global.send')}</Button>
      </Flex>
    </Form.Item>
  </ProForm>;
};