import { MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { MutateAsyncType } from '../../../types/react-query';

export const ForgotPasswordForm = ({
  mutateAsync,
}: {
  mutateAsync: MutateAsyncType;
}) => {
  const { t } = useTranslation();

  return (
    <Form
      name="forgotPassword"
      onFinish={async values => {
        await mutateAsync({
          successNotification: () => false,
          resource: `auth/forgot-password`,
          values,
          errorNotification: false,
        });
      }}
      layout="vertical"
    >
      <Form.Item
        name="email"
        label={t('page.auth.forgotPassword.emailLabel')}
        rules={[
          {
            required: true,
            message: t('global.requiredField'),
          },
        ]}
      >
        <Input
          prefix={<MailOutlined style={{ marginRight: '4px' }} />}
          placeholder={t('page.auth.forgotPassword.emailPlaceholder')}
        />
      </Form.Item>
      <Form.Item>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Button type="primary" htmlType="submit" block>
            {t('global.send')}
          </Button>
          <Button type="link" href="/auth">
            {t('page.auth.forgotPassword.backToLogin')}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
