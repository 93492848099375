import {
  GlobalOutlined,
  HomeOutlined,
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useCustom } from '@refinedev/core';
import { Card, Space, Typography } from 'antd';
import styled from 'styled-components';
import { LoadingComponent } from '../../components/shared/loading';
import { CustomAvatar } from './avatar';
import { SingleElementForm } from './single-element-form';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { UserProfileType } from '../../types/user';

const { Title, Text } = Typography;

export const UserProfile = () => {
  useDocumentTitle({ i18nKey: 'page.userProfile.title' })
  const { data, isLoading, isError } = useCustom<UserProfileType>({
    url: 'user/profile',
    method: 'get',
  });

  const { t } = useTranslation();

  const user = data?.data;

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <Text>{t('global.errors.general')}</Text>;
  }

  if (!user) {
    return <Text>{t('page.userProfile.errors')}</Text>;
  }

  return (
    <>
      <Container>
        <Content>
          <ProfileSection>
            <CustomAvatar
              style={{
                marginRight: '1rem',
                flexShrink: 0,
                fontSize: '40px',
              }}
              size={96}
              name={`${user.firstName} ${user.lastName}`}
            />
            <Title level={3} style={{ padding: 0, margin: 0, width: '100%' }}>
              {user.firstName} {user.lastName}
            </Title>
          </ProfileSection>

          <Card
            title={
              <Space size={15}>
                <UserOutlined />
                <Text>{t('page.userProfile.title')}</Text>
              </Space>
            }
            styles={{
              header: {
                padding: '0 12px',
              },
              body: {
                padding: '0',
              },
            }}
          >
            <FormSection>
              <SingleElementForm
                icon={<MailOutlined />}
                itemProps={{ label: t('page.userProfile.fields.email') }}
                view={<Text>{user.email}</Text>}
              />
              <SingleElementForm
                icon={<GlobalOutlined />}
                itemProps={{ label: t('page.userProfile.fields.address') }}
                view={<Text>{user.address}</Text>}
              />
              <SingleElementForm
                icon={<HomeOutlined />}
                itemProps={{ label: t('page.userProfile.fields.city') }}
                view={<Text>{user.city}</Text>}
              />
              <SingleElementForm
                icon={<PhoneOutlined />}
                itemProps={{ label: t('page.userProfile.fields.phone') }}
                view={<Text>{user.phoneNumber}</Text>}
              />
              <SingleElementForm
                icon={<IdcardOutlined />}
                itemProps={{ label: t('page.userProfile.fields.zipCode') }}
                view={<Text>{user.zipCode}</Text>}
              />
            </FormSection>
          </Card>
        </Content>
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: 16px;
  max-width: 600px;
`;

const Content = styled.div`
  padding: 16px;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
`;
