import { HttpError } from '@refinedev/core';
import axios from 'axios';
import { ActionType } from '../../contexts/auth';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../auth/constants';
import { clearSessionAndDispatchLogout } from '../auth/helpers/logout';
import { refreshSessionAndLogin } from '../auth/helpers/refresh-session';
import { isValidToken } from '../auth/helpers';

const HTTP_STATUS_UNAUTHORIZED_CODE = 401;
const TOKEN_EXPIRATION_THRESHOLD = 60; // 60 seconds
const REFRESH_TOKEN_URL = 'refresh-access-token';

export const axiosInstance = axios.create({});

export const setupAxiosInterceptors = (
  dispatch: React.Dispatch<ActionType>,
) => {
  axiosInstance.interceptors.request.use(
    async config => {
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

      if (accessToken && !config?.url?.endsWith(REFRESH_TOKEN_URL)) {
        if (!isValidToken(accessToken, TOKEN_EXPIRATION_THRESHOLD)) {
          try {
            const newAccessToken = await refreshSessionAndLogin(dispatch);
            if (newAccessToken) {
              config.headers.Authorization = `Bearer ${newAccessToken}`;
            }
          } catch (error) {
            clearSessionAndDispatchLogout(dispatch);
            return Promise.reject(error);
          }
        } else {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }

      if (refreshToken && config?.url?.endsWith(REFRESH_TOKEN_URL)) {
        config.headers.Authorization = `Bearer ${refreshToken}`;
      }

      return config;
    },
    async error => {
      const customError: HttpError = {
        ...error,
        message: error.response?.data?.message,
        statusCode: error.response?.status,
        isAxiosError: true,
      };

      return Promise.reject(customError);
    },
  );

  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalConfig = error.config;

      const customError: HttpError = {
        ...error,
        message: error.response?.data?.message,
        statusCode: error.response?.status,
        isAxiosError: true,
      };

      if (error.response) {
        const isAccessTokenExpired =
          error.response.status === HTTP_STATUS_UNAUTHORIZED_CODE &&
          !originalConfig._retry;

        const isRefreshTokenExpired =
          (error.response.status === HTTP_STATUS_UNAUTHORIZED_CODE &&
            error.request.responseURL.endsWith(REFRESH_TOKEN_URL));
            
        if (isRefreshTokenExpired) {
          clearSessionAndDispatchLogout(dispatch);
        } else if (isAccessTokenExpired) {
          originalConfig._retry = true;

          try {
            await refreshSessionAndLogin(dispatch);

            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(customError);
    },
  );
};
