import { createContext } from 'react';
import { AuthContextType, InitialStateType } from './vo';

export const initialState: InitialStateType = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export const AuthContext = createContext<AuthContextType>({
  state: initialState,
  dispatch: () => undefined,
  login: async () => {
    return { success: false };
  },
  refreshToken: async () => '',
  logout: async () => {
    return { success: false };
  },
  isAdmin: false,
  userRoles: []
});
