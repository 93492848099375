import type { RefineThemedLayoutV2HeaderProps } from '@refinedev/antd';
import { Layout as AntdLayout } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom'
import styled from "styled-components";
import HeaderIcon from '../../assets/header-icon.svg';
import { authenticatedRoutes } from '../../routes/authenticated-routes';
import { useIsMobile } from '../../hooks';

const { Header: AntdHeader } = AntdLayout;

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = () => {
  const { pathname } = useLocation();

  const { isMobileWithTablet } = useIsMobile();

  const shouldHideLogo = authenticatedRoutes.some(r => pathname.includes(r));

  return (
      <StyledHeader>
        <Container>
          <Content>
            <div className="shrink">
              {(!shouldHideLogo || isMobileWithTablet) && <a href="https://aksel.nu" target={"_blank"} title="Aksel" className="site-logo-link">
                <img src={HeaderIcon} alt="Aksel" className="site-link"/>
              </a>}
            </div>
          </Content>
        </Container>
      </StyledHeader>
  );
};

const StyledHeader = styled(AntdHeader)`
    box-shadow: 0 0 10px rgb(11 33 45 / 15%);
    background: #fff;
    padding: 0;
    height: max-content;
    `;

export const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 1225px;
    padding-right: 1.25rem;
    padding-left: 2.5rem;

    @media print,screen and (width >= 40em) {
       padding-right: 1.5625rem;
    }
`;

const Content = styled.div`
    align-items: center;
    display: flex;
    transition: all .6s;
    height: 70px;

    @media print,screen and (width >= 40em) {
       height: 90px;
    }

    @media print,screen and (width >= 64em) {
       height: 70px;
    }

    @media screen and (width >= 75em) {
       height: 70px;
    }

    @media print,screen and (width >= 64em) {
        height: 70px;
    }

    .site-logo-link {
        .site-link {
            width: 120px;
            transition: all .6s
        }

        @media print,screen and (width >= 40em) {
            .site-link {
                width:120px
            }
        }

        @media print,screen and (width >= 64em) {
            .site-link {
                width:124px
            }
        }

        @media screen and (width >= 75em) {
            .site-link {
                width:124px;
            }
        }
    }
    `;

