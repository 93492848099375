import { DashboardOutlined, ProfileOutlined } from '@ant-design/icons';
import { ResourceProps as RefineResourceProps } from '@refinedev/core';
import { RoleType } from '../types/user';
import {
  DeprecatedOptions,
  ResourceMeta,
} from '@refinedev/core/dist/contexts/resource/types';

interface ExtendedResourceMeta extends ResourceMeta {
  label: string;
  icon: React.ReactNode;
  roles: RoleType[];
}

export interface ResourceProps extends RefineResourceProps {
  name: string;
  key?: string;
  options?: ExtendedResourceMeta & DeprecatedOptions;
  meta: ExtendedResourceMeta & DeprecatedOptions;
  icon?: React.ReactNode;
  parentName?: string;
}

export const resources: ResourceProps[] = [
  {
    name: 'biler',
    list: '/biler',
    meta: {
      label: 'Biler',
      icon: <DashboardOutlined />,
      roles: [RoleType.REGULAR_USER],
    },
  },
  {
    name: 'admin/biler',
    list: '/admin/biler',
    meta: {
      label: 'Biler',
      icon: <DashboardOutlined />,
      roles: [RoleType.SUPER_ADMIN],
    },
  },
  {
    name: 'profil',
    list: '/profil',
    meta: {
      label: 'Profil',
      icon: <ProfileOutlined />,
      roles: [RoleType.REGULAR_USER, RoleType.SUPER_ADMIN],
    },
  },
] satisfies ResourceProps[];
