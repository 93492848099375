import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  EyeOutlined,
  FieldTimeOutlined,
  HeartOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LockTwoTone,
  RightOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { useToken } from '@ant-design/pro-components';
import Skeleton from '@ant-design/pro-skeleton';
import { HttpError, useCustom, useGo, useParsed } from '@refinedev/core';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { Button, Col, Collapse, Flex, Row, Statistic, Table, TableProps, Tag, Tooltip, Typography } from 'antd';
import { ItemType } from 'rc-collapse/es/interface';
import { useTranslation } from 'react-i18next';
import { UploadImagesForm } from '../../components';
import { CarInfo } from '../../components/car-info';
import { LoadingComponent } from '../../components/shared/loading';
import { useAuthContext } from '../../contexts/auth';
import { Deal } from '../../utils/deal-base.dto';
import { formatPriceToDanishLocale } from '../../utils/format-price-to-danish-locale';
import { DealStatus, getStatusOrder } from '../partner/vo/submission';
import { DealAllTimeStats, DealDailyStats, DealDetailsParam } from './vo/deal-details.dto';
import { ErrorComponent } from '../error/404';
import React from 'react';
import ColumnChart from '@ant-design/plots/es/components/column';


const WaitingIcon = <ClockCircleTwoTone twoToneColor={'gray'} style={{ fontSize: 24, marginLeft: 24 }} />;
const NotAvailableIcon = <LockTwoTone twoToneColor={'grey'} style={{ fontSize: 24, marginLeft: 24 }} />;
const RequireActionIcon = <WarningTwoTone twoToneColor={'orange'} style={{ fontSize: 24, marginLeft: 24 }} />;
const FinishedStepIcon = <CheckCircleTwoTone twoToneColor={'green'} style={{ fontSize: 24, marginLeft: 24 }} />;
const AcceptPriceComponent = ({ dealId }: { dealId: string }) => {
  return (
    <Flex style={{ marginLeft: 24 }}>
      <Button type="primary" href={`/accept-pris/${dealId}`}>Accepter pris</Button>
      <WarningTwoTone
        twoToneColor={'orange'}
        style={{ fontSize: 24, marginLeft: 12 }}
      />
    </Flex>
  );
};

type ClientType = 'buyer' | 'seller';

type StepType = ItemType & {
  availableFor: Array<ClientType>;
  expandable: boolean;
};

const staticLegendTableColumn: TableProps<unknown>['columns'] = [
  {
    title: '',
    dataIndex: 'condition',
    key: 'condition',
    render: (text, __, idx) => {
      return <Tag color={idx === 0 ? 'red' : idx === 1 ? 'yellow' : 'green'} key={text}>{text}</Tag>;
    },
  },
  {
    title: 'Visninger per dag',
    dataIndex: 'viewsRange',
    align: 'center',
    key: 'viewsRange',
  },
  {
    title: () => {
      return <Flex wrap={false} gap={2} align={'center'} justify={'center'}>
        <div>Total gemt som favorit</div>
        <div>
          <Tooltip title={'Alle, der har gemt som favorit, får notifikation, hvis prisen justeres'}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>

      </Flex>;
    },
    align: 'center',
    dataIndex: 'favoriteRange',
    key: 'favoriteRange',
  },
];

const staticLegendTableData = [
  {
    condition: 'Kræver handling',
    viewsRange: '< 10',
    favoriteRange: '0',
  },
  {
    condition: 'Okay',
    viewsRange: '10-25',
    favoriteRange: '1-3',
  },
  {
    condition: 'God',
    viewsRange: '+25',
    favoriteRange: '+3',
  },
];

function generateEmptyDataForChart(startFrom: Date = new Date(), days: number = 7) {
  return [...Array(days).keys()].map((i) => ({
    statsForDay: new Date(startFrom.getTime() - ((days - i) * 24 * 60 * 60 * 1000)).toISOString(),
    viewsTotal: '0',
    savedAsFavorite: '0',
  }));
}

export const DealDetailsPage = () => {
  const { token } = useToken();
  const { user } = useAuthContext();
  useDocumentTitle({ i18nKey: 'page.dealDetails.pageMetaTitle' });
  const { t } = useTranslation();

  const { Title } = Typography;

  const go = useGo();

  const { params } = useParsed<DealDetailsParam>();

  const { data, isLoading, isRefetching, error } = useCustom<Deal, HttpError>({
    url: `deal/${params?.id}`,
    method: 'get',
    queryOptions: {
      enabled: !!params?.id,
    },
  });

  const { data: dealAdAllTimeData, isLoading: isLoadingAllTimeData } = useCustom<DealAllTimeStats>({
    url: `deal-ad-stats/${params?.id}/all-time`,
    method: 'get',
    queryOptions: {
      enabled: !!params?.id,
    },
  });


  const { data: dealAdDailyStats } = useCustom<Array<DealDailyStats>>({
    url: `deal-ad-stats/${params?.id}/daily`,
    method: 'get',
    queryOptions: {
      enabled: !!params?.id,
    },
  });

  const deal = data?.data;
  const seller = deal?.sellerId === user?.clientId;

  const order = getStatusOrder(deal?.status);
  const askingPrice = deal?.askingPrice;
  const formattedPrice = formatPriceToDanishLocale(askingPrice);

  const items: Array<StepType> = ([
    {
      key: getStatusOrder(DealStatus.UNDER_REVIEW),
      availableFor: ['seller'],
      label: `${t('page.dealDetails.statusOptions.UNDER_REVIEW.BASE')} ${deal?.status === DealStatus.UNDER_REVIEW
        ? `- ${t('page.dealDetails.statusOptions.UNDER_REVIEW.WAITING_FOR_ADMIN_REVIEW')}` : ''}`,
      extra: deal?.status === DealStatus.UNDER_REVIEW ? WaitingIcon : FinishedStepIcon,
      showArrow: false,
      expandable: false,
      collapsible: 'icon',
    },
    {
      availableFor: ['seller'],
      key: getStatusOrder(DealStatus.WAITING_PRICE_ACCEPTANCE).toString(),
      label: `${t('page.dealDetails.statusOptions.WAITING_PRICE_ACCEPTANCE.BASE')} ${deal?.status === DealStatus.WAITING_PRICE_ACCEPTANCE
        ? `- ${t('page.dealDetails.statusOptions.WAITING_PRICE_ACCEPTANCE.REQUIRE_ACCEPTANCE', { price: formattedPrice })}`
        : order > getStatusOrder(DealStatus.WAITING_PRICE_ACCEPTANCE)
          ? ''
          : `- ${t('page.dealDetails.statusOptions.COMMON.NOT_AVAILABLE_YET')}`
      }`,
      extra: deal?.status === DealStatus.WAITING_PRICE_ACCEPTANCE
        ? <AcceptPriceComponent dealId={deal?.id} />
        : order < getStatusOrder(DealStatus.WAITING_PRICE_ACCEPTANCE)
          ? NotAvailableIcon
          : FinishedStepIcon,
      expandable: false,
      showArrow: false,
      children: <p>Accept Price Form</p>,
      collapsible: 'icon',
    },
    {
      availableFor: ['seller'],
      key: getStatusOrder(DealStatus.WAITING_FOR_IMAGES).toString(),
      label: `${t('page.dealDetails.statusOptions.WAITING_FOR_IMAGES.BASE')}`,
      extra: deal?.status === DealStatus.WAITING_FOR_IMAGES
        ? RequireActionIcon
        : order < getStatusOrder(DealStatus.WAITING_FOR_IMAGES)
          ? NotAvailableIcon
          : FinishedStepIcon,
      expandable: true,
      showArrow: (order >= getStatusOrder(DealStatus.WAITING_FOR_IMAGES)),
      collapsible: 'icon',
      children: <UploadImagesForm deal={deal} />,
    },
    {
      key: getStatusOrder(DealStatus.ADVERTISEMENT_CREATED).toString(),
      label: order <= getStatusOrder(DealStatus.ADVERTISEMENT_CREATED) ? t('page.dealDetails.statusOptions.ADVERTISEMENT_CREATED.BASE')
        : t('page.dealDetails.statusOptions.ADVERTISEMENT_LIVE.BASE'),
      availableFor: ['seller'],
      extra: deal?.status === DealStatus.ADVERTISEMENT_CREATED
        ? WaitingIcon
        : order < getStatusOrder(DealStatus.ADVERTISEMENT_CREATED)
          ? NotAvailableIcon
          : FinishedStepIcon,
      expandable: false,
      showArrow: false,
      collapsible: 'icon',
      children: <p>Upload Images Form Here</p>,
    },
    {
      key: getStatusOrder(DealStatus.SELL_AGREED).toString(),
      label: order <= getStatusOrder(DealStatus.SELL_AGREED) ? t('page.dealDetails.statusOptions.SELL_AGREED.BASE')
        : t('page.dealDetails.statusOptions.SOLD.BASE'),
      availableFor: ['seller', 'buyer'],
      extra: deal?.status === DealStatus.SELL_AGREED
        ? WaitingIcon
        : deal?.status === DealStatus.SOLD
          ? FinishedStepIcon
          : order < getStatusOrder(DealStatus.SELL_AGREED)
            ? NotAvailableIcon
            : FinishedStepIcon,
      expandable: false,
      showArrow: false,
      collapsible: 'icon',
    },
  ] satisfies Array<StepType> as Array<StepType>).filter((item) => seller ? item.availableFor.includes('seller') : item.availableFor.includes('buyer'));


  if (isLoading) {
    return <Skeleton type="result" />;
  }

  if (error) {
    return <ErrorComponent />;
  }

  return <div>
    <Flex
      justify={'space-between'}
      align={'center'}
    >
      <Button
        style={{
          alignSelf: 'flex-start',
          marginBottom: '1rem',
        }}
        icon={<HomeOutlined color={token.colorPrimary} />} onClick={() => go({
        to: params?.from === 'admin' ? '/admin/biler' : '/biler',
      })} />
      {isRefetching && <LoadingComponent size={'small'} justify={'right'} />}
    </Flex>

    <CarInfo {...deal} />

    <Collapse
      style={{ marginLeft: '-16px' }}
      expandIcon={({ isActive }) => (
        <div style={{ marginTop: '6px' }}><RightOutlined
          rotate={isActive ? 90 : 0}
        /></div>
      )}
      defaultActiveKey={[deal?.status === DealStatus.ADVERTISEMENT_LIVE ? '1' : '2']} ghost items={[
      {
        key: '1',
        label: <Title style={{ marginBottom: 0 }} level={3}> Statistik</Title>,
        children: <div>
          <Row gutter={16} align={'middle'} style={{ marginBottom: '3rem', marginLeft: '1rem' }}>
            <Col flex={1}>
              <Statistic
                style={{marginLeft: "-8px"}}
                loading={isLoadingAllTimeData}
                title="Liggetid"
                value={dealAdAllTimeData?.data?.daysOnMarket || 0}
                prefix={<FieldTimeOutlined />} />
            </Col>
            <Col flex={1}>
              <Statistic
                loading={isLoadingAllTimeData}
                title="Visninger"
                value={dealAdAllTimeData?.data?.viewsTotal || 0}
                prefix={<EyeOutlined />} />
            </Col>
            <Col flex={1}>
              <Statistic
                loading={isLoadingAllTimeData}
                title="Gemt som favorit"
                value={dealAdAllTimeData?.data?.savedAsFavorite || 0}
                prefix={<HeartOutlined />} />
            </Col>
          </Row>
          <Title level={5} style={{paddingLeft: '1rem'}}>
            {
              dealAdAllTimeData?.data?.updatedAt && `Sidst opdateret ${new Date(dealAdAllTimeData?.data?.updatedAt || '').toLocaleString('en-GB')}`
              || `Statistik er først tilgængelig når annoncen er live`
            }
          </Title>
          <ColumnChart
            data={(dealAdDailyStats?.data?.length || 0 > 0
              ? [...((dealAdDailyStats?.data?.length || 0) < 7 ? generateEmptyDataForChart(
                new Date(dealAdDailyStats?.data[0]?.statsForDay || 0),
                7 - (dealAdDailyStats?.data?.length || 0),
              ) : []), ...dealAdDailyStats?.data || []]
              : generateEmptyDataForChart()).flatMap((item) => {

              const date = new Date(item.statsForDay).toLocaleDateString('da-DK', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              });

              return [
                {
                  viewsTotal: Number.parseInt(item.viewsTotal),
                  type: 'Visninger',
                  statsForDay: date,
                },
                {
                  viewsTotal: Number.parseInt(item.savedAsFavorite),
                  type: 'Favorit',
                  statsForDay: date,
                },
              ];
            })}
            interaction={{
              tooltip: { shared: true },
              elementHighlight: { background: true },
            }}
            stack={true}
            colorField={'type'}
            xField={'statsForDay'}
            yField={'viewsTotal'}
            scale={{
              color: { range: [token.colorPrimary, token.colorWarning] },
            }}
          ></ColumnChart>
          <Table
            style={{ maxWidth: '512px', marginLeft: '1rem' }}
            dataSource={staticLegendTableData}
            columns={staticLegendTableColumn}
            pagination={false}
          />

        </div>,
      },
    ]}>
    </Collapse>


    <Title style={{ marginTop: '1rem' }} level={3}>Status</Title>

    <Collapse
      style={{
        marginTop: '1rem',
      }}
      accordion
      defaultActiveKey={items.find(item => item.key == order && item.expandable)?.key as string}
      items={items}
    >
    </Collapse>
  </div>;

};
