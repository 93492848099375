import { DollarTwoTone, RightCircleTwoTone } from '@ant-design/icons';
import { ProCard, ProDescriptions, useToken } from '@ant-design/pro-components';
import { TagField } from '@refinedev/antd';
import { useGo, useList } from '@refinedev/core';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { Flex, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { NoDataYetText } from '../../components/no-data';
import { useAuthContext } from '../../contexts/auth';
import { Deal } from '../../utils/deal-base.dto';
import { formatPriceToDanishLocale } from '../../utils/format-price-to-danish-locale';
import { DealStatus } from '../partner/vo/submission';

export const DealsPage = () => {
  const { user } = useAuthContext();
  const { Text, Title } = Typography;
  const { token } = useToken();
  const go = useGo();

  useDocumentTitle({ i18nKey: 'page.cars.pageMetaTitle' });

  const { data, isLoading } = useList<Deal>({
    resource: 'deal',
    pagination: {
      pageSize: 100,
      current: 0,
      mode: 'server',
    },
  });

  const deals = data?.data;

  if (deals?.length === 0) {
    return <NoDataYetText />;
  } else {
    return <div>
      <Title level={2}>Biler</Title>
      <ProCard.Group
        loading={isLoading}
        wrap
        style={{ marginBlockStart: 16, background: 'none' }}
        // bodyStyle={{gap: 8}}
        gutter={8}
      >
        {deals?.map(deal => {
          return (<ProCard
            key={deal.id}
            hoverable
            bordered
            style={{ marginTop: 8 }}
            onClick={() => {
              go({
                to: `/biler/${deal.id}`,
              });
            }}
            headerBordered
            title={

              <Text>
                <Tooltip placement="top" title={user?.clientId === deal.buyerId ? 'Køber' : 'Sælger'}>
                  <DollarTwoTone
                    style={{
                      paddingInlineEnd: 2,
                    }}
                    twoToneColor={user?.clientId === deal.buyerId ? 'green' : 'red'}
                  />
                </Tooltip>

                {deal.make} {deal.model} {deal.variant}

              </Text>
            }
            extra={<Text style={{ color: token.colorPrimary }}>
              {formatPriceToDanishLocale(deal.askingPrice)},-
            </Text>}
            colSpan={{
              xs: 24,
              sm: 24,
              md: 24,
              xxl: 8,
              xl: 8,
              lg: 12,
            }}
          >
            <Flex wrap={false} justify={'space-between'}>

              <ProDescriptions
                column={2}
                size={'small'}
              >
                <ProDescriptions.Item
                  valueType={'text'}
                  span={2}
                  label={'Nummerplade'}
                >
                  {deal.licensePlate?.toUpperCase()}
                </ProDescriptions.Item>

                <ProDescriptions.Item
                  valueType={'text'}
                  span={2}
                  label={t('common.year')}
                >
                  {deal.year}
                </ProDescriptions.Item>
                <ProDescriptions.Item
                  span={2}
                  tooltip={''}
                  label={t('common.status')}
                >
                  <TagField
                    bordered={false}
                    value={t(`page.partnerPortal.table.status.${deal.status}`)}
                    color={deal.status === DealStatus.WAITING_PRICE_ACCEPTANCE ? 'orange' : 'blue'}
                  />
                </ProDescriptions.Item>

                <ProDescriptions.Item
                  valueType={'date'}
                  span={2}
                  label={t('common.createdAt')}
                >
                  {dayjs(deal.createdAt).valueOf()}
                </ProDescriptions.Item>


              </ProDescriptions>
              <RightCircleTwoTone style={{ fontSize: '32px' }} twoToneColor={token.colorPrimary} />
            </Flex>

          </ProCard>)
        })}
      </ProCard.Group>
    </div>

  }


};
