import { TFunction } from 'i18next';
import {
  getUserFromSession,
  isValidToken,
  setAuthSession,
  setRefreshToken,
} from '.';
import { ActionType, ActionTypes } from '../../../contexts/auth';
import { InitialStateType } from '../../../contexts/auth/vo';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../constants';
import { clearSessionAndDispatchLogout } from './logout';
import { refreshSessionAndLogin } from './refresh-session';

export const refreshOrInitializeAuthSession = async (
  state: InitialStateType,
  dispatch: React.Dispatch<ActionType>,
  t: TFunction,
) => {
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);
  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);

  if (accessToken && refreshToken && isValidToken(refreshToken)) {
    const user = getUserFromSession(accessToken);
    const isAccessTokenValid = isValidToken(accessToken);

    if (accessToken && !isAccessTokenValid) {
      setRefreshToken(refreshToken);
      await refreshSessionAndLogin(dispatch);
      return user;
    } else {
      setAuthSession(accessToken);
      setRefreshToken(refreshToken);

      if (!user) {
        throw new Error(t('global.errors.login.user_data_retrieval_fail'));
      }

      dispatch({
        type: ActionTypes.INITIALIZE,
        payload: {
          isAuthenticated: true,
          user: { ...(state.user ?? {}), ...user },
        },
      });

      return user;
    }
  } else {
    await clearSessionAndDispatchLogout(dispatch);
  }
};
