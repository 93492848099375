import { useEffect, useRef, useState } from 'react';
import { UppyService } from './uppy/service';
import Uppy from '@uppy/core';
import { PreSignednUrlOptions } from './uppy/constants';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import '@uppy/drop-target/dist/style.css';

interface IUseFileUploader {
    uppyInstance: Uppy
}

const restrictionsOptions = {
  restrictions: {
    maxFileSize: 20 * 1024 * 1024,
    maxNumberOfFiles: 100,
    allowedFileTypes: ['image/*'],
  },
};

export const useFileUploader = (options: PreSignednUrlOptions): IUseFileUploader => {
  const uppyServiceInstance = useRef<UppyService>(new UppyService({
    ...restrictionsOptions,
    autoProceed: options.autoSave || false
  }));
  const [instance, setInstance] = useState<Uppy>(() => uppyServiceInstance.current.register(options));
  const initialized = useRef<boolean>(false);
  const isClosed = useRef<boolean>(false);

  const initializeUppy = () => {
    const newInstance = new UppyService(restrictionsOptions);
    uppyServiceInstance.current = newInstance;
    setInstance(newInstance.register(options));
    isClosed.current = false;
  };

  const closeUppy = () => {
    if (instance && (initialized.current || process.env.NODE_ENV !== 'development')) {
      instance.close({ reason: 'unmount' });
      isClosed.current = true;
    }
    initialized.current = true;
  };

  useEffect(() => {
    if (isClosed.current) {
      initializeUppy();
    }

    return closeUppy;
  }, []);


  return { uppyInstance: instance };
};