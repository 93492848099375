import { Typography } from 'antd';
import { CSSProperties } from 'react';

const { Text } = Typography;

type ListItemProps = { style?: CSSProperties; };

export const ListItem = ({ children, style }: React.PropsWithChildren<ListItemProps>) => (
  <li style={style}>
    <Text>{children}</Text>
  </li>
);