import { CheckCard } from '@ant-design/pro-components';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { YesNoDontKnowOptionSelector } from '../../../yes-or-no';
import { useIsMobile } from '../../../../hooks';

export const ChipTuningQuestionForm = () => {
  const { t } = useTranslation();
  const { isMobileWithTablet } = useIsMobile();

  return (
    <>
      <Form.Item
        label={t(
          'page.dealDetails.uploadImagesForm.questions.chipTuned.question',
        )}
        name="chipTuned"
        rules={[{ required: true, message: t('global.requiredField') }]}
      >
        <CheckCard.Group style={{ width: '100%' }} size="small">
          <YesNoDontKnowOptionSelector
            fullWidth
            vertical={isMobileWithTablet}
            includeDontKnowOption
            justify="flex-start"
          />
        </CheckCard.Group>
      </Form.Item>

      <Form.Item
        style={{ paddingLeft: '24px' }}
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.chipTuned !== currentValues.chipTuned
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('chipTuned') === true ? (
            <Form.Item
              label={t(
                'page.dealDetails.uploadImagesForm.questions.chipTuned.followup',
              )}
              name="chipTuningDocumentation"
              rules={[{ required: true, message: t('global.requiredField') }]}
            >
              <CheckCard.Group style={{ width: '100%' }} size="small">
                <YesNoDontKnowOptionSelector
                  includeDontKnowOption
                  fullWidth
                  justify="flex-start"
                />
              </CheckCard.Group>
            </Form.Item>
          ) : null
        }
      </Form.Item>
    </>
  );
};
