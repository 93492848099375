import { Divider, Flex, Space, Typography } from 'antd';
import { ImagesFileUploader, ImagesFileUploaderProps } from '../images-file-uploader';
import { List } from '../list/list';

const { Title } = Typography;

const advicePoints = [
  'Tag nærbilleder af eventuelle skader, ridser eller buler, så sælger får en reel opfattelse af bilens stand.',
  'Det er langt mere troværdigt og så undgår du, at handlen ryger i vasken, når køber kommer ud og ser bilen.',
];

export const InjuriesGuide = ({
  dealId,
  formRef,
  fileUploader,
  isLoading,
}: ImagesFileUploaderProps) => {
  return (
    <Flex vertical>
      <Flex justify="center">
        <Title level={3}>Skader</Title>
      </Flex>
      <Space direction="vertical" size={'large'}>
        <List items={advicePoints} />
        <Flex gap={24} wrap justify='center' align="center">
          <ImagesFileUploader
            name='injuries'
            formRef={formRef}
            dealId={dealId}
            autoSave={true}
            fileUploader={fileUploader}
            isLoading={isLoading}
            required={false}
          />
          <Divider />
        </Flex>
      </Space>
    </Flex>
  );
};
