import { MobileOutlined } from '@ant-design/icons';
import { Divider, Flex, Space, Typography } from 'antd';
import baggrundOk from '../../../../assets/images/picture-guide/baggrund-correct.webp';
import baggrundx1 from '../../../../assets/images/picture-guide/baggrund-incorrect1.webp';
import lpnx1 from '../../../../assets/images/picture-guide/lpn-incorrect.webp';
import rengorx1 from '../../../../assets/images/picture-guide/rengor-incorrect1.webp';
import rengorx2 from '../../../../assets/images/picture-guide/rengor-incorrect2.webp';
import { UploadImagesCard } from '../image-card';
import { List } from '../list/list';

const { Title } = Typography;

const advicePoints = [
  'Hold kameraet vandret.',
  'Rengør bilen grundigt både indvendigt og udvendigt.',
  'Sørg for, at bilen fylder mest muligt i billedet.',
  'Tag billederne op ad en rolig og neutral baggrund.',
  'Undlad at sløre nummerpladen, det skaber et utroværdigt udtryk.',
];

export const GeneralAdvice = () => {
  return (
    <Flex vertical>
      <Flex justify="center">
        <Title level={3}>Generelle råd</Title>
      </Flex>
      <Space direction="vertical" size={'large'}>
        <List items={advicePoints} />
        <Flex wrap gap={24} align="center" justify='center'>
          <UploadImagesCard imageSrc={baggrundx1} color="red" />
          <UploadImagesCard imageSrc={rengorx1} color="red" />
          <UploadImagesCard imageSrc={rengorx2} color="red" />
          <UploadImagesCard imageSrc={lpnx1} color="red" />
          <UploadImagesCard imageSrc={baggrundOk} color="green" />

          <UploadImagesCard
            bordered
            cardStyle={{
              height: '160px',
              background: 'transparent',
              display: 'flex',
              justifyContent: 'center',
            }}
            imageSrc={
              <MobileOutlined
                style={{
                  fontSize: '160px',
                  rotate: '90deg',
                  background: 'none',
                }}
              />
            }
            color="green"
          />
          <Divider />
        </Flex>
      </Space>
    </Flex>
  );
};
