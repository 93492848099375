import {
  CheckOutlined,
  CloseOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import { CheckCard } from '@ant-design/pro-components';
import { Avatar, Flex, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../../hooks';

export const ServiceDocumentationQuestionForm = () => {
  const { t } = useTranslation();
  const { isMobileWithTablet } = useIsMobile();

  return (
    <Form.Item
      name="serviceDocumentation"
	    style={{ marginBottom: '80px' }}
      label={t(
        'page.dealDetails.uploadImagesForm.questions.serviceDocumentation.question',
      )}
      rules={[{ required: true, message: t('global.requiredField') }]}
    >
      <CheckCard.Group style={{ width: '100%' }} size="small">
        <Flex vertical={isMobileWithTablet} justify={'flex-start'}>
          <CheckCard
            style={{ minWidth: '212px', width: '100%' }}
            title={t(
              'page.dealDetails.uploadImagesForm.questions.serviceDocumentation.options.YES',
            )}
            avatar={<Avatar icon={<CheckOutlined />} size="small" />}
            value={'YES'}
          />
          <CheckCard
            style={{ minWidth: '212px', width: '100%' }}
            title={t(
              'page.dealDetails.uploadImagesForm.questions.serviceDocumentation.options.PARTIALLY',
            )}
            avatar={<Avatar icon={<CloseOutlined />} size="small" />}
            value={'PARTIALLY'}
          />
          <CheckCard
            style={{ minWidth: '212px', width: '100%' }}
            title={t(
              'page.dealDetails.uploadImagesForm.questions.serviceDocumentation.options.NO_INFO',
            )}
            avatar={<Avatar icon={<QuestionOutlined />} size="small" />}
            value="NO_INFO"
          />
        </Flex>
      </CheckCard.Group>
    </Form.Item>
  );
};
