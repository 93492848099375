import { useBreakpoint } from '@ant-design/pro-components';

export const useIsMobile = () => {
    const breakpoint = useBreakpoint();
 const mobileBreakpoints = ['xs', 'sm', 'md'];
  const isMobile = breakpoint === 'xs' || breakpoint === 'sm' || false;
  const isTablet = breakpoint === 'md' || false;
  const isDesktop = breakpoint === 'lg' || breakpoint === 'xl' || breakpoint === 'xxl';
  const isMobileWithTablet = typeof breakpoint === 'undefined'
      ? false
      : mobileBreakpoints.includes(breakpoint);
 // used for backwards compatibility

  return { isMobile, isTablet, isMobileWithTablet, isDesktop };
};
