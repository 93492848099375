import {
  DownloadOutlined,
  HomeOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { useToken } from '@ant-design/pro-components';
import Skeleton from '@ant-design/pro-skeleton';
import { HttpError, useCustom, useGo, useParsed } from '@refinedev/core';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { Button, Flex, Image, Space, Tooltip, Typography } from 'antd';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import React from 'react';
import CarThumbnail from '../../../../assets/car-thumbnail.svg';
import { CarInfo } from '../../../../components/car-info';
import { UploadImagesCard } from '../../../../components/deal-details/upload-images/image-card';
import { useAuthContext } from '../../../../contexts/auth';
import { dataProvider } from '../../../../provider';
import { Deal } from '../../../../utils/deal-base.dto';
import { DealDetailsParam } from '../../../deal-details/vo/deal-details.dto';
import { ErrorComponent } from '../../../error/404';

const { Title } = Typography;

export const AdminDealDetailsPage = () => {
  useDocumentTitle({ i18nKey: 'page.admin.deal.details.meta.title' });
  const { isAdmin } = useAuthContext();
  const go = useGo();
  const { token } = useToken();

  const params = useParsed<DealDetailsParam>();

  const [isDownloadingFiles, setDownloadingFiles] = React.useState(false);

  const { data, isLoading, error } = useCustom<Deal, HttpError>({
    url: `deal/${params?.id}/admin`,
    method: 'get',
    queryOptions: {
      enabled: !!params?.id && isAdmin,
    },
  });

  const onDownloadAll = async (deal: Deal) => {
    setDownloadingFiles(true);
    try {
      const carDetails = deal?.getCaradsId ? `${deal?.getCaradsId}` : `${deal.make}-${deal.model}-${deal.year}`;

      const allImages = deal.images.map(async (img, idx) => {
        const imgName = `${idx}-`+ img.originalName;

        const res = await fetch(
          `${dataProvider.getApiUrl()}/file/dealer-car/download-file/${img.urlKey}`,
        );

        const blob = await res.blob();
        return {
          imgName,
          blob,
        };
      });

      const zip = new JSZip();

      const folder = zip.folder(carDetails)!;

      const imagesDownloaded = await Promise.all(allImages);

      imagesDownloaded.forEach(img => {
        folder.file(img.imgName, img.blob, {
          binary: true,
        });
      });

      await zip.generateAsync({ type: 'blob' }).then(function (content) {
        // see FileSaver.js
        saveAs(content, `${carDetails}.zip`);
      });
    } finally {
      setDownloadingFiles(false);
    }
  };

  const onDownload = (src: string, fileName: string) => {
    fetch(src)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  const deal = data?.data;

  if (error) {
    return <ErrorComponent />;
  }

  return isLoading ? (
    <Skeleton type={'descriptions'} />
  ) : (
    params?.id && deal && (
      <Flex
        style={{ maxWidth: 1120, margin: '0 auto', padding: 24 }}
        vertical={true}
        gap={'small'}
        align={'center'}
      >
        <Button
          style={{
            alignSelf: 'flex-start',
          }}
          icon={<HomeOutlined color={token.colorPrimary} />}
          onClick={() =>
            go({
              to: '/admin/biler',
            })
          }
        />

        <CarInfo
          {...deal}
          mileage={deal.mileage}
          gear={deal.gear}
          askingPrice={deal?.askingPrice}
        />

        <Image.PreviewGroup
          preview={{
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: {
                  onFlipY,
                  onFlipX,
                  onRotateLeft,
                  onRotateRight,
                  onZoomOut,
                  onZoomIn,
                },
                current,
              },
            ) => (
              <Space size={14}>
                <DownloadOutlined
                  style={{ fontSize: 24 }}
                  onClick={() =>
                    onDownload(
                      `${dataProvider.getApiUrl()}/file/dealer-car/download-file/${deal.images[current]?.urlKey}`,
                      `${deal.make}-${deal.model}-${deal.year}-${deal.images[current]?.originalName}`,
                    )
                  }
                />
                <SwapOutlined
                  style={{ fontSize: 24 }}
                  rotate={90}
                  onClick={onFlipY}
                />
                <SwapOutlined style={{ fontSize: 24 }} onClick={onFlipX} />
                <RotateLeftOutlined
                  style={{ fontSize: 24 }}
                  onClick={onRotateLeft}
                />
                <RotateRightOutlined
                  style={{ fontSize: 24 }}
                  onClick={onRotateRight}
                />
                <ZoomOutOutlined
                  style={{ fontSize: 24 }}
                  disabled={scale === 1}
                  onClick={onZoomOut}
                />
                <ZoomInOutlined
                  style={{ fontSize: 24 }}
                  disabled={scale === 50}
                  onClick={onZoomIn}
                />
              </Space>
            ),
          }}
        >
          <Flex
            style={{
              width: '100%',
            }}
            vertical={true}
          >
            <Flex
              vertical={false}
              align={'center'}
              justify={'flex-start'}
              gap={'middle'}
            >
              <Title
                level={1}
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                }}
              >
                Billeder
              </Title>
              <Tooltip title={'Download Alle'} placement={'right'}>
                <Button
                  loading={isDownloadingFiles}
                  disabled={isDownloadingFiles}
                  shape={'circle'}
                  type={'primary'}
                  icon={<DownloadOutlined />}
                  onClick={() => onDownloadAll(deal)}
                />
              </Tooltip>
            </Flex>
            <Flex
              align={'flex-start'}
              wrap={true}
              style={{
                paddingTop: 24,
                width: '100%',
              }}
              vertical={false}
              gap={'small'}
            >
              {deal?.images?.length > 0 && deal.images.map((image, index) => {
                const src = `${dataProvider.getApiUrl()}/file/dealer-car/download-file/${image.urlKey}`;
                return (
                  <UploadImagesCard
                    key={`${image.originalName}-${image.urlKey}-${index}`}
                    imageSrc={src}
                    noMark
                    preview
                    isSquareShape
                    placeholder={<Image src={CarThumbnail} />}
                  />
                );
              })}
            </Flex>
          </Flex>
        </Image.PreviewGroup>
      </Flex>
    )
  );
};
