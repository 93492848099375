import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { ProFormText } from '@ant-design/pro-components';
import { RefineThemedLayoutV2HeaderProps } from '@refinedev/antd';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { useTranslation } from 'react-i18next';
import { passwordValidationPattern } from '../../utils/validation/password-validation.pattern';

export const LoginPage: React.FC<RefineThemedLayoutV2HeaderProps> = () => {
  useDocumentTitle({ i18nKey: 'page.auth.registration.pageMetaTitle' });
  const { t } = useTranslation();

  return (
    <>
      <ProFormText
        name="email"
        label={t('common.user.email.label')}
        fieldProps={{
          size: 'large',
          prefix: <UserOutlined className={'prefixIcon'} />,
        }}
        placeholder={t('common.user.email.placeholder')}
        rules={[{ required: true, message: t('global.requiredField') }]}
      />

      <ProFormText.Password
        name="password"
        label={t('page.auth.registration.fields.password.label')}
        fieldProps={{
          size: 'large',
          prefix: <LockOutlined className={'prefixIcon'} />,
        }}
        placeholder={t('page.auth.registration.fields.password.placeholder')}
        rules={[
          { required: true, message: t('global.requiredField') },
          {
            pattern: passwordValidationPattern,
            message: t('page.auth.registration.fields.password.constraint'),
          },
        ]}
      />
    </>
  );
};
