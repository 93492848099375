import { LoginForm } from '@ant-design/pro-components';
import { RefineThemedLayoutV2HeaderProps } from '@refinedev/antd';
import { useGo, useLogin, useRegister } from '@refinedev/core';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { Button, Flex, Form, Tabs, Typography } from 'antd';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { useIsMobile } from '../../hooks';
import { LoginParams } from '../../provider/auth/vo/signin-response.interface';
import { SignUpParams } from '../../provider/auth/vo/signup-response.interface';
import { UserProfileType } from '../../types/user';

const HTTP_STATUS_CONFLICT_CODE = 409;
const HTTP_STATUS_UNPROCESSABLE_ENTITY = 422;

const getAuthDetails = (t: TFunction) => {
  return {
    login: {
      resource: 'signin',
      title: t('page.auth.login.welcomeTitle'),
      submit: t('page.auth.login.fields.submit'),
    },
    signup: {
      resource: 'signup',
      title: t('page.auth.registration.welcomeTitle'),
      submit: t('page.auth.registration.fields.submit'),
    },
  };
};

export const AuthLayout: React.FC<RefineThemedLayoutV2HeaderProps> = () => {
  const { pathname } = useLocation();
  const loginType = pathname.includes('tilmelding') ? 'signup' : 'login';
  
  const i18nKey = loginType === 'signup' ? 'page.auth.registration.pageMetaTitle' : 'page.auth.login.pageMetaTitle'
  useDocumentTitle({ i18nKey });
  
  const go = useGo();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onChange = (key: string) => {
    form.resetFields();
    const route = key === 'login' ? 'login' : 'tilmelding';
    go({ to: `/auth/${route}` });
  };

  const { mutate: login } = useLogin<LoginParams>();
  const { data: signUpResult, mutate: register, isLoading: isLoadingCreate} = useRegister<SignUpParams>();

  const details = getAuthDetails(t)[loginType];

  if (signUpResult?.success === false) {
    const axiosError = signUpResult?.error as AxiosError;

    if (axiosError.response?.status === HTTP_STATUS_CONFLICT_CODE) {
      form.setFields([
        {
          name: 'email',
          errors: [t('page.auth.registration.fields.email.alreadyExists')],
        },
      ]);
    } else if (
      axiosError.response?.status === HTTP_STATUS_UNPROCESSABLE_ENTITY
    ) {
      form.setFields([
        {
          name: 'email',
          errors: [
            t('page.auth.registration.fields.email.notRegisteredInPipedrive'),
          ],
        },
      ]);
    }
  }


  const onFinish = async (values: UserProfileType & Omit<LoginParams, 'redirectPage'>) => {
    if (details.resource === 'signin') {
      login({ ...values, redirectPage: 'biler' });
      return;
    }

    register({ ...values, redirectPage: 'biler' })
  };

 const { isMobileWithTablet } = useIsMobile();

  return <LoginForm
      form={form}
      contentStyle={{ minWidth: isMobileWithTablet ? 'initial' : '460px', width: '100%', minHeight: '100vh' }}
      title={details.title}
      onFinish={onFinish}
      submitter={{
        submitButtonProps: false,
        resetButtonProps: false,
        render: () => [],
      }}
      style={{ maxWidth: 460, margin: '0 auto', padding: isMobileWithTablet ? 0 : 24, paddingTop: 24 }}
    >
      <Tabs
        centered
        activeKey={loginType}
        onChange={onChange}
        items={[
          {
            key: 'login',
            label: t('page.auth.tabs.login'),
          },
          {
            key: 'signup',
            label: t('page.auth.tabs.signUp'),
          },
        ]}
      />
      <Outlet />
      <Flex justify={'center'}>
        <Button
          size={'large'}
          loading={isLoadingCreate}
          htmlType={'submit'}
          type={'primary'}
          shape={'round'}
        >
          {details.submit}
        </Button>
      </Flex>
      {details.resource === 'signin' && (
        <Flex justify="center" style={{ marginTop: '6px' }}>
          <Typography.Link underline={true} href={`/auth/glemt-adgangskode`}>
            Glemt adgangskode?
          </Typography.Link>
        </Flex>
      )}
    </LoginForm>
};
