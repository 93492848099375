import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';

export const ResetPasswordSuccess = () => {
  const { t } = useTranslation();
  return (
    <Result
      status="success"
      title={t('page.auth.resetPassword.success')}
      subTitle={t('page.auth.resetPassword.successSubMessage')}
      extra={[
        <Button key="login" type="primary" href="/auth">
          {t('page.auth.forgotPassword.backToLogin')}
        </Button>,
      ]}
    />
  );
};
