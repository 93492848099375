import { ProFormInstance } from '@ant-design/pro-components';
import { Form, FormItemProps, Typography } from 'antd';
import FileUploader, { IFileUploaderProps } from '../../file-uploader';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../hooks';

const { Text } = Typography;

export type ImagesFileUploaderProps = {
  fileUploader: React.RefObject<IFileUploaderProps>;
  isLoading: boolean;
  autoSave?: boolean;
  dealId: string | undefined;
  label?: string;
  formRef: React.MutableRefObject<ProFormInstance | undefined>;
  formItemProps?: FormItemProps<unknown>;
  required?: boolean;
};

type ImagesFileUploaderPropsType = ImagesFileUploaderProps & {
  name: string
};

export const ImagesFileUploader = ({
                                     fileUploader,
                                     isLoading,
                                     formRef,
                                     dealId,
                                     name,
                                     autoSave,
                                     label,
                                     formItemProps,
                                     required = true,
                                   }: ImagesFileUploaderPropsType) => {
  const { t } = useTranslation();

  const { isMobileWithTablet, isTablet, isMobile } = useIsMobile();

  const getHeight = () => {
    switch (true) {
      case isMobile:
        return '120px';
      case isTablet:
        return '200px';
      case isMobileWithTablet:
        return '220px';
      default:
        return '200px';
    }
  };

  if (!dealId) {
    return null;
  }

  const rules = required ? [{ required: true, message: t('global.requiredField') }] : undefined;

  return (
    <Form.Item
      style={{ marginTop: '40px', width: '90%' }}
      name={name}
      label={label ?? 'Uploade bilbilleder'}
      rules={rules}
      {...formItemProps}
    >
      <FileUploader
        width={'100%'}
        height={getHeight()}
        ref={fileUploader}
        orgOrDealId={dealId}
        disabled={isLoading}
        autoSave={autoSave}
        setFilesData={files => {
          formRef.current?.setFieldValue(name, files);
          formRef.current?.validateFields([name]);
        }}
      />
      <Text>Tryk i feltet og tag billede eller upload</Text>
    </Form.Item>
  );
};
