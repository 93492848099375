import {
  CheckOutlined,
  CloseOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import { CheckCard } from '@ant-design/pro-components';
import { Avatar, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

type YesNoDontKnowOptionSelectorProps = {
  justify?: React.CSSProperties['justifyContent'];
  includeDontKnowOption?: boolean;
  vertical?: boolean;
  fullWidth?: boolean;
}

export const YesNoDontKnowOptionSelector = ({ justify = 'center', includeDontKnowOption = false, vertical = false, fullWidth = false }: YesNoDontKnowOptionSelectorProps) => {
  const { t } = useTranslation();

  const cardStyle: React.CSSProperties = fullWidth ? { width: '100%' } : {};

  return (
    <>
      <Flex vertical={vertical} justify={justify}>
        <CheckCard
          style={cardStyle}
          title={t('global.yes')}
          avatar={<Avatar icon={<CheckOutlined />} size="small" />}
          value={true}
        />
        <CheckCard
          style={cardStyle}
          title={t('global.no')}
          avatar={<Avatar icon={<CloseOutlined />} size="small" />}
          value={false}
        />
        {includeDontKnowOption && <CheckCard
          style={cardStyle}
          title={t('global.dontKnow')}
          avatar={<Avatar icon={<QuestionOutlined />} size="small" />}
          value="dontKnow"
        />}
      </Flex>
    </>
  );
};
