import { getUserFromSession, setAuthSession } from '.';
import { ActionType, ActionTypes } from '../../../contexts/auth';
import { refreshToken } from '../api';

export const refreshSessionAndLogin = async (dispatch: React.Dispatch<ActionType>) => {
  const response = await refreshToken();

  const { accessToken } = response;
  const user = getUserFromSession(accessToken);
  setAuthSession(accessToken);

  dispatch({
    type: ActionTypes.LOGIN,
    payload: {
      user,
    },
  });
  
  return accessToken;
};
