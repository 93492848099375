import { Skeleton, Typography } from 'antd';
import styled from 'styled-components';

type SingleElementFormProps = {
  icon?: React.ReactNode;
  itemProps?: { label?: string };
  view?: React.ReactNode;
  loading?: boolean;
  style?: React.CSSProperties;
};

export const SingleElementForm: React.FC<SingleElementFormProps> = ({
  view,
  icon,
  itemProps,
  loading,
  style,
}) => {
  return (
    <Container style={style}>
      <IconWrapper>{icon}</IconWrapper>
      <Content>
        <InputWrapper>
          <Typography.Text type="secondary">{itemProps?.label}</Typography.Text>
          {loading && <Skeleton.Input size="small" active />}
          {!loading && view}
        </InputWrapper>
        {/* <Actions>
          <Button icon={<EditOutlined />} disabled />
        </Actions> */}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  min-height: 64px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  padding: 0.5rem 0.8rem;
  border-bottom: 1px solid rgba(217 217 217 / "100%");
`;

const IconWrapper = styled.div`
  padding-right: 15px;
  align-self: flex-start;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputWrapper = styled.div`
  flex: 1;
  margin-right: 0.8rem;

  .ant-skeleton-input {
    height: 1rem;
  }

  .ant-typography {
    user-select: none;
    display: block;
  }
`;

// we can integrate it later, so we can leave it
// const Actions = styled.div`
//   align-self: center;
//   display: flex;
//   gap: 0.5rem;
// `;
