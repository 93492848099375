import { merge } from '@ant-design/pro-components';
import AwsS3, { AwsS3UploadParameters } from '@uppy/aws-s3';
import Uppy, { UppyFile, UppyOptions } from '@uppy/core';
import ImageEditor from '@uppy/image-editor';
import { MAX_FILE_SIZE_10_MB, PreSignednUrlOptions } from './constants';
import { PreSignedResponse } from './dto';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import Danish from '@uppy/locales/lib/da_DK';
import { dataProvider } from '../../../provider';

export class UppyService {
  private readonly uppy: Uppy;

  constructor(awsS3Options?: UppyOptions) {
    this.uppy = new Uppy({
      ...this.mergeS3Options(awsS3Options),
      debug: false,
      locale: Danish
    });
  }

  private readonly defaultS3Restrictions = {
    autoProceed: false,
    restrictions: {
      maxFileSize: MAX_FILE_SIZE_10_MB,
      maxNumberOfFiles: 9,
      allowedFileTypes: null,
    },
  };

  public register(options?: PreSignednUrlOptions) {
    this.uppy
      // .use(Dashboard, { inline: true, target: document.body })
      .use(ImageEditor, {
        locale: {
          strings: {
            revert: 'Tilbage',
            rotate: 'Roter',
            zoomIn: 'Zoom ind',
            zoomOut: 'Zoom ud',
            flipHorizontal: 'Vend vandret',
            aspectRatioSquare: 'Beskær kvadratisk',
            aspectRatioLandscape: 'Beskær bredformat (16:9)',
            aspectRatioPortrait: 'Beskær portræt (9:16)',
          },
        },
      })
      // .use(DropTarget, {
      //   target: document.body,
      // })
      .use(AwsS3, {
        getUploadParameters: async (file: UppyFile) => {
          return await this.getUploadParameters(file, options);
        },
      });

    this.subscribeOnEvents();

    return this.uppy;
  }

  public async getUploadParameters({
                                     name,
                                     type,
                                     id,
                                   }: UppyFile, options?: PreSignednUrlOptions): Promise<AwsS3UploadParameters> {
    const { data } = await dataProvider.custom<PreSignedResponse[]>({
      url: `file/dealer-car/get-upload-presign-url`,
      method: 'post',
      payload: {
        filesData: [{
          originalFileName: name,
          ...(options && options?.filePackage && { folder: options.filePackage }),
        }],
      },
    });
    const { preSignedUrl, key, originalFileName } = data[0];

    if (!preSignedUrl) {
      throw new Error('PreSigned URL not received');
    }

    this.uppy.setFileMeta(id, { key, preSignedUrl, originalFileName });

    return {
      method: 'PUT',
      url: preSignedUrl,
      fields: {},
      headers: {
        'Content-Type': type || '',
      },
    };
  }

  public subscribeOnEvents() {
    this.uppy.on('upload-success', async (file) => {
      await this.completeUploading(file);
    });
  }

  public async completeUploading(file: UppyFile | undefined) {
    try {
      this.uppy.emit('complete', file);
    } catch (error) {
      this.uppy.emit('upload-error', file, error);
    }
  }

  public mergeS3Options = (options?: UppyOptions) => {
    return {
      ...merge(this.defaultS3Restrictions, options),
      debug: true,
    };
  };
}