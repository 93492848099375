import { HttpError } from '@refinedev/core';
import { useState } from 'react';
import { Deal } from '../../utils/deal-base.dto';
import { CarsList } from '../../components/cars';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { useTable } from '@refinedev/antd';

export const CarsListPage = () => {
  useDocumentTitle({ i18nKey: 'page.admin.deal.list.pageMetaTitle' });

  const [search, setSearch] = useState('');

  const tableData = useTable<Deal, HttpError, { search: string }>({
    resource: 'deal/get-all-for-admin',
    pagination: {
      mode: 'server',
      pageSize: 20,
    },
    meta: {
      search: search.trim(),
    },
    onSearch: (values: { search: string }) => {
      setSearch(values.search);
      return [];
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc',
        },
      ],
    },
    syncWithLocation: false,
    queryOptions: {
      retry: 0,
    },
  });

  const error = tableData.tableQueryResult?.error;
  const isLoading = tableData.tableQueryResult.isLoading;

  const isError = error?.statusCode && error?.statusCode > 399;

  return (
    <CarsList isLoading={isLoading} isError={isError} tableData={tableData} />
  );
};
