import React from 'react';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { PartnerB2bSubmissionForm } from '../../components/partner-b2b-submission-form';
import { useParsed } from '@refinedev/core';
import { PartnerPortalRouteParams } from '../partner/vo/route-params';

export const PartnerB2bSubmissionPage: React.FC = () => {
  useDocumentTitle({ i18nKey: 'page.partnerCarSubmission.pageMetaTitle' });

  const { params } = useParsed<PartnerPortalRouteParams>();


  return params?.organisationId && <PartnerB2bSubmissionForm
    organisationId={params?.organisationId}
  /> || null;
};