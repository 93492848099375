import { AxiosError } from 'axios';
import { QueryClientConfig } from '@tanstack/react-query'

const ONE_MINUTE_IN_MS = 60 * 1000;
const MAX_RETRY_COUNT = 3;

export enum QueryMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
}

export const queryClientOptions = {
  defaultOptions: {
    queries: {
      useErrorBoundary: error => {
        return (error as { code: number }).code !== 401;
      },
      staleTime: ONE_MINUTE_IN_MS,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        const isMaxRetry = failureCount === MAX_RETRY_COUNT;
        const isAuthorizationError =
          (error as AxiosError)?.response?.status === 401;
        return !isAuthorizationError && !isMaxRetry;
      },
    },
  },
} satisfies QueryClientConfig;
