import { LockOutlined } from '@ant-design/icons';
import { LoginForm, ProFormText } from '@ant-design/pro-components';
import { ParsedParams } from '@refinedev/core';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../hooks';
import { ApprovalEmailParamsData } from '../../../types/auth';
import { passwordValidationPattern } from '../../../utils/validation/password-validation.pattern';

export const ResetPasswordForm = ({
  refetch,
  params,
}: {
  refetch: (values: any) => Promise<void>;
  params: ParsedParams<ApprovalEmailParamsData> | undefined;
}) => {
  const { t } = useTranslation();

  const handleFormSubmit = async (values: any) => {
    await refetch({ ...values, id: params?.id, code: params?.code });
  };

  const { isMobileWithTablet } = useIsMobile();

  return (
    <LoginForm
      contentStyle={{ minWidth: 'initial', width: isMobileWithTablet ? '100%' : '440px' }}
      onFinish={handleFormSubmit}
      submitter={{
        submitButtonProps: false,
        resetButtonProps: false,
        render: () => [],
      }}
      style={{ maxWidth: 440, margin: '0 auto', padding: 0 }}
    >
      <ProFormText.Password
        name="password"
        fieldProps={{
          size: 'middle',
          prefix: <LockOutlined className={'prefixIcon'} />,
        }}
        placeholder={t('page.auth.registration.fields.password.placeholder')}
        label={t('page.auth.registration.fields.password.label')}
        rules={[
          {
            required: true,
            message: t('global.requiredField'),
          },
          {
            pattern: passwordValidationPattern,
            message: t('page.auth.registration.fields.password.constraint'),
          },
        ]}
      />

      <ProFormText.Password
        dependencies={['password']}
        name="repeatedPassword"
        fieldProps={{
          size: 'middle',
          prefix: <LockOutlined className={'prefixIcon'} />,
        }}
        label={t('page.auth.registration.fields.password.confirm')}
        placeholder={t(
          'page.auth.registration.fields.password.confirmPlaceholder',
        )}
        rules={[
          {
            required: true,
            message: t('page.auth.registration.fields.password.confirm')!,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  t(
                    'page.auth.registration.fields.password.repeatedDoNotMatch',
                  ),
                ),
              );
            },
          }),
        ]}
      />
      <Button type="primary" htmlType="submit" block>
        {t('page.auth.resetPassword.submit')}
      </Button>
    </LoginForm>
  );
};
