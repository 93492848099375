import { LockOutlined } from '@ant-design/icons';
import { ProFormDigit, ProFormText } from '@ant-design/pro-components';
import { Row, Col } from 'antd';
import { t } from 'i18next';
import { passwordValidationPattern } from '../../../utils/validation/password-validation.pattern';

export const SignupForm = () => {
  return (
    <>
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={12}>
          <ProFormText
            name="firstName"
            label={t('common.user.firstName.label')}
            placeholder={t('common.user.firstName.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
        <Col xs={24} sm={12}>
          <ProFormText
            name="lastName"
            label={t('common.user.lastName.label')}
            placeholder={t('common.user.lastName.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col xs={24} sm={12}>
          <ProFormText
            disabled
            name="email"
            label={t('common.user.email.label')}
            placeholder={t('common.user.email.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
        <Col xs={24} sm={12}>
          <ProFormText
            name="phoneNumber"
            label={t('common.user.phone.label')}
            placeholder={t('common.user.phone.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col xs={24} sm={12}>
          <ProFormText.Password
            name="password"
            fieldProps={{
              size: 'middle',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={t(
              'page.auth.registration.fields.password.placeholder',
            )}
            label={t('page.auth.registration.fields.password.label')}
            rules={[
              { required: true, message: t('global.requiredField') },
              {
                pattern: passwordValidationPattern,
                message: t('page.auth.registration.fields.password.constraint'),
              },
            ]}
          />
        </Col>
        <Col xs={24} sm={12}>
          <ProFormText.Password
            dependencies={['password']}
            name="repeatedPassword"
            fieldProps={{
              size: 'middle',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            label={t('page.auth.registration.fields.password.confirm')}
            placeholder={t(
              'page.auth.registration.fields.password.confirmPlaceholder',
            )}
            rules={[
              {
                required: true,
                message: t('page.auth.registration.fields.password.confirm')!,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t(
                        'page.auth.registration.fields.password.repeatedDoNotMatch',
                      ),
                    ),
                  );
                },
              }),
            ]}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={8}>
          <ProFormText
            name="address"
            label={t('common.user.address.label')}
            placeholder={t('common.user.address.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
        <Col xs={24} sm={8}>
          <ProFormText
            name="city"
            label={t('common.user.addressCity.label')}
            placeholder={t('common.user.addressCity.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
        <Col xs={24} sm={8}>
          <ProFormDigit
            name="zipCode"
            min={0}
            max={9999}
            fieldProps={{ precision: 0 }}
            label={t('common.user.zipCode.label')}
            placeholder={t('common.user.zipCode.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
      </Row>
    </>
  );
};
