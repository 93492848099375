import { CheckCard } from '@ant-design/pro-components';
import { Flex, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../../hooks';
import { YesNoDontKnowOptionSelector } from '../../../yes-or-no';

export const EngineQuestionForm = () => {
  const { t } = useTranslation();
  const { isMobileWithTablet } = useIsMobile();

  const conditionOptions = t('common.condition', { returnObjects: true });

  return (
    <Flex vertical>
      <Form.Item
        label={t(
          'page.dealDetails.uploadImagesForm.questions.engineHasBeenReplaced.label',
        )}
        name="motor"
        rules={[{ required: true, message: t('global.requiredField') }]}
      >
        <CheckCard.Group style={{ width: '100%' }} size="small">
          <YesNoDontKnowOptionSelector
            fullWidth
            vertical={isMobileWithTablet}
            includeDontKnowOption
            justify="flex-start"
          />
        </CheckCard.Group>
      </Form.Item>

      <Form.Item
        style={{ paddingLeft: '24px' }}
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.motor !== currentValues.motor
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('motor') === true ? (
            <Form.Item
              label={t(
                'page.dealDetails.uploadImagesForm.questions.engineHasBeenReplaced.followup',
              )}
              name="engineReplacementType"
              rules={[{ required: true, message: t('global.requiredField') }]}
            >
              <Select>
                {Object.entries(conditionOptions).map(([key, value]) => (
                  <Select.Option key={`${key}-${value}`} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null
        }
      </Form.Item>
    </Flex>
  );
};
