import React from 'react';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { useGo, useOne, useParsed } from '@refinedev/core';
import { PartnerSubmissionPortalRouteParams } from '../partner/vo/route-params';
import { Button, Flex, Image, Space, theme, Tooltip, Typography } from 'antd';
import { CarInfo } from '../../components/car-info';
import { Submission, DealStatus } from '../partner/vo/submission';
import { useTranslation } from 'react-i18next';
import {
  DownloadOutlined,
  HomeOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { AcceptPriceForm } from '../../components/accept-price';
import Skeleton from '@ant-design/pro-skeleton';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const { Title } = Typography;
import CarThumbnail from '../../assets/car-thumbnail.svg';
import { dataProvider } from '../../provider';


const { useToken } = theme;

export const PartnerB2bSubmissionViewPage: React.FC = () => {
  useDocumentTitle({ i18nKey: 'page.partnerCarSubmission.pageMetaTitle' });
  const { t } = useTranslation();
  const go = useGo();
  const { token } = useToken();

  const {
    params: {
      organisationId,
      id,
    } = {},
  } = useParsed<PartnerSubmissionPortalRouteParams>();

  const [isDownloadingFiles, setDownloadingFiles] = React.useState(false);

  const { isLoading, data, refetch } = useOne<Submission>({
    resource: 'partner-deals/' + organisationId,
    id,
  });

  const onDownloadAll = async (submission: Submission) => {
    setDownloadingFiles(true);
    try {
      const carDetails = `${submission.make}-${submission.model}-${submission.year}`;

      const allImages = submission.images.map(async img => {
        const imgName = img.originalName;

        const res = await fetch(`${dataProvider.getApiUrl()}/file/dealer-car/download-file/${img.urlKey}`);

        const blob = await res.blob();
        return {
          imgName,
          blob,
        };

      });

      const zip = new JSZip();

      const folder = zip.folder(carDetails)!;

      const imagesDownloaded = await Promise.all(allImages);

      imagesDownloaded.forEach(img => {
        folder.file(img.imgName, img.blob, {
          binary: true,
        });
      });

      await zip.generateAsync({ type: 'blob' }).then(function(content) {
        // see FileSaver.js
        saveAs(content, `${carDetails}.zip`);
      });
    } finally {
      setDownloadingFiles(false);
    }
  };

  const onDownload = (src: string, fileName: string) => {
    fetch(src)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  const submission = data?.data;

  return isLoading ? <Skeleton type={'descriptions'} /> : organisationId && id && submission && <Flex
    style={{ maxWidth: 1120, margin: '0 auto', padding: 24 }}
    vertical={true}
    gap={'small'}
    align={'center'}
  >
    <Button
      style={{
        alignSelf: 'flex-start',
      }}
      icon={<HomeOutlined color={token.colorPrimary} />} onClick={() => go({
      to: '/partner/' + organisationId,
    })} />

    {
      submission.status === DealStatus.WAITING_PRICE_ACCEPTANCE
      && <Flex
        style={{
          width: '100%',
        }}
        align={'center'} justify={'flex-end'}>
        <AcceptPriceForm
          onSuccess={async () => {
            await refetch();
          }}
          organisationId={organisationId}
          id={id}
        />
      </Flex>
    }

    <CarInfo
      {...submission}
      mileage={submission.mileage}
      gear={submission.gear}
      askingPrice={submission.askingPrice}
    />

    <Image.PreviewGroup
      preview={{
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: {
              onFlipY,
              onFlipX,
              onRotateLeft,
              onRotateRight,
              onZoomOut,
              onZoomIn,
            },
            current,
          },
        ) => (
          <Space size={14}>
            <DownloadOutlined
              style={{ fontSize: 24 }}
              onClick={() => onDownload(`${dataProvider.getApiUrl()}/file/dealer-car/download-file/${submission.images[current]?.urlKey}`,
                `${submission.make}-${submission.model}-${submission.year}-${submission.images[current]?.originalName}`)} />
            <SwapOutlined
              style={{ fontSize: 24 }}
              rotate={90} onClick={onFlipY} />
            <SwapOutlined
              style={{ fontSize: 24 }}
              onClick={onFlipX} />
            <RotateLeftOutlined
              style={{ fontSize: 24 }}
              onClick={onRotateLeft} />
            <RotateRightOutlined
              style={{ fontSize: 24 }}
              onClick={onRotateRight} />
            <ZoomOutOutlined
              style={{ fontSize: 24 }}
              disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined
              style={{ fontSize: 24 }}
              disabled={scale === 50} onClick={onZoomIn} />
          </Space>
        ),
      }}
    >
      <Flex
        style={{
          width: '100%',
        }}
        vertical={true}
      >

        <Flex vertical={false} align={'center'} justify={'flex-start'} gap={'middle'}>
          <Title level={1}
                 style={{
                   paddingBottom: 0,
                   marginBottom: 0,
                 }}
          >
            Billeder
          </Title>
          <Tooltip
            title={'Download Alle'}
            placement={'right'}>
            <Button
              loading={isDownloadingFiles}
              disabled={isDownloadingFiles}
              shape={'circle'}
              type={'primary'}
              icon={<DownloadOutlined />} onClick={() => onDownloadAll(submission)} />
          </Tooltip>

        </Flex>
        <Flex align={'flex-start'}
              wrap={true}
              style={{
                paddingTop: 24,
                width: '100%',
              }}
              vertical={false} gap={'small'}>
          {
            data?.data?.images.map((image) => {
              const src = `${dataProvider.getApiUrl()}/file/dealer-car/download-file/${image.urlKey}`;
              return <Image
                width={120}
                src={src}
                placeholder={<Image src={CarThumbnail} />}
              />;
            })
          }
        </Flex>
      </Flex>
    </Image.PreviewGroup>
  </Flex>;
};