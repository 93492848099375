import { Flex, Result, Button } from 'antd';

export const AcceptPriceExpiredError = () => {
  return (
    <Flex vertical align="center">
      <Result
        status="error"
        title={'Vurderingen er ikke længere aktiv'}
        subTitle={'Markedet rykker sig meget hurtigt, så vurderingen er desværre kun gældende i 14 dage. Du er meget velkommen til at få en opdateret vurdering.'}
      />
      <Button type="primary" size="large" href={'https://aksel.nu/byttebil/'}>
        Få en opdateret vurdering
      </Button>
    </Flex>
  );
};
