import { useGo } from '@refinedev/core';
import { Button, Flex, Result } from 'antd';
import { useTranslation } from 'react-i18next';

export const AcceptPriceError = () => {
  const { t } = useTranslation();
  const go = useGo();

  return (
    <Flex vertical align='center'>
      <Result
        status="500"
        title={t('page.acceptPrice.errorTitle')}
        subTitle={t('page.acceptPrice.errorMessage')}
      />
      <Button
        type="primary"
        size="large"
        onClick={() => go({ to: '/auth' })}
      >
        {t('page.auth.forgotPassword.backToLogin')}
      </Button>
    </Flex>
  );
};
