import { AuthProvider } from '@refinedev/core';
import { TFunction } from 'i18next';
import { ActionType, ActionTypes } from '../../contexts/auth';
import { InitialStateType } from '../../contexts/auth/vo';
import { RoleType } from '../../types/user';
import { signIn, signUp } from './api';
import { saveAuthDataAndDispatchLogin } from './helpers';
import { refreshOrInitializeAuthSession } from './helpers/check-auth';
import { isAxiosError } from './helpers/is-axios-error';
import { clearSessionAndDispatchLogout } from './helpers/logout';
import { LoginParams } from './vo/signin-response.interface';
import { SignUpParams } from './vo/signup-response.interface';
import { AxiosError } from 'axios';

const buildRedirectLink = (link: string, isAdmin: boolean) => {
  return isAdmin ? `/admin/${link}` : `/${link}`;
};

export const createRefineAuthProvider = (
  state: InitialStateType,
  dispatch: React.Dispatch<ActionType>,
  t: TFunction,
): AuthProvider => ({
  login: async ({ email, password, redirectPage }: LoginParams) => {
    try {
      const { accessToken, refreshToken } = await signIn(email, password);

      const user = saveAuthDataAndDispatchLogin(
        accessToken,
        refreshToken,
        dispatch,
      );
      const roles = user?.roles?.map(role => role.roleType);
      const isAdmin = roles?.includes(RoleType.SUPER_ADMIN) ?? false;

      return {
        success: true,
        redirectTo: redirectPage
          ? buildRedirectLink(redirectPage, isAdmin)
          : '/',
      };
    } catch (error) {
      let errorMessage = t('global.errors.general');
      let errorName = t('global.errors.login.login_error');

      if (isAxiosError(error)) {
        if (error.response) {
          if (error.response.status === 401) {
            errorMessage = t('global.errors.login.invalid_credentials');
            errorName = t('global.errors.login.unauthorized');
          } else {
            errorMessage =
              error.response.data?.detail ||
              error.message ||
              error.statusText ||
              errorMessage;
            errorName = error.response.data?.title || error.name || errorName;
          }
        } else {
          errorMessage = error.message || errorMessage;
          errorName = t('global.errors.login.network_error');
        }
      } else if (error instanceof Error) {
        errorMessage = error.message || errorMessage;
        errorName = error.name || errorName;
      }

      return {
        success: false,
        error: {
          message: errorMessage,
          name: errorName,
        },
      };
    }
  },
  register: async (data: SignUpParams) => {
    try {
      const { accessToken, refreshToken } = await signUp(data);

      const user = saveAuthDataAndDispatchLogin(
        accessToken,
        refreshToken,
        dispatch,
      );
      const roles = user?.roles?.map(role => role.roleType);
      const isAdmin = roles?.includes(RoleType.SUPER_ADMIN) ?? false;
      const redirectPage = data?.redirectPage;

      return {
        success: true,
        data: user,
        redirectTo: redirectPage && buildRedirectLink(redirectPage, isAdmin),
      };
    } catch (error) {
      return {
        success: false,
        error: error as AxiosError,
      };
    }
  },
  logout: async () => {
    try {
      await clearSessionAndDispatchLogout(dispatch);

      return {
        success: true,
        redirectTo: '/auth',
      };
    } catch (error) {
      return {
        success: false,
        redirectTo: '/auth',
      };
    }
  },
  onError: async error => {
    if (error.status === 401 || error.status === 403) {
      return {
        logout: true,
        redirectTo: '/auth',
        error,
      };
    }

    return { error };
  },
  check: async () => {
    try {
      const user = await refreshOrInitializeAuthSession(state, dispatch, t);
      if (!user) {
        return {
          authenticated: false,
          error: {
            message: t('global.errors.login.session_expired'),
            name: t('global.errors.login.session_expired_name'),
          },
          redirectTo: '/auth',
        };
      }
      return {
        authenticated: true,
      };
    } catch (error) {
      dispatch({
        type: ActionTypes.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      return {
        authenticated: false,
        error: {
          message: t('global.errors.login.session_expired_or_invalid'),
          name: t('global.errors.login.access_denied'),
        },
        logout: true,
        redirectTo: '/auth',
      };
    }
  },
});
