import {
  LeftOutlined,
  LogoutOutlined,
  MenuOutlined,
  RightOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { useToken } from '@ant-design/pro-components';
import { Sider, useThemedLayoutContext } from '@refinedev/antd';
import {
  CanAccess,
  ITreeMenu,
  useIsExistAuthentication,
  useLogout,
  useMenu,
  useWarnAboutChange,
} from '@refinedev/core';
import {
  Layout as AntdLayout,
  Button,
  Drawer,
  Layout,
  Menu,
  Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../contexts/auth';
import { useIsMobile } from '../../hooks';
import { RoleType } from '../../types/user';
import { AkselIcon } from '../shared/icons';
import { antLayoutSider, drawerButtonStyles } from './styles';

const PRIMARY_TEXT_COLOR = '#1f1f1f';
const SECONDARY_TEXT_COLOR = '#fff';

export const CustomSider: typeof Sider = ({ render }) => {
  const { token } = useToken();
  const { isAdmin, userRoles } = useAuthContext();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { mobileSiderOpen, setMobileSiderOpen } = useThemedLayoutContext();
  const isExistAuthentication = useIsExistAuthentication();
  const { warnWhen, setWarnWhen } = useWarnAboutChange();
  const { mutate: mutateLogout } = useLogout();
  const { t } = useTranslation();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const { SubMenu } = Menu;

  const adminMenuItems = menuItems.filter(item =>
    item?.meta?.roles?.includes(RoleType.SUPER_ADMIN),
  );

  const defaultMenuItems = menuItems.filter(item =>
    item?.meta?.roles?.some((role: RoleType) => userRoles.includes(role)),
  );

  const { isMobileWithTablet } = useIsMobile();

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { name, children, meta, key, list } = item;

      const icon = meta?.icon;
      const label = meta?.label ?? name;
      const parent = meta?.parent;
      const route =
        typeof list === 'string'
          ? list
          : typeof list !== 'function'
            ? list?.path
            : key;

      if (children.length > 0) {
        return (
          <SubMenu
            key={route}
            icon={icon ?? <UnorderedListOutlined />}
            title={label}
          >
            {renderTreeView(children, selectedKey)}
          </SubMenu>
        );
      }
      const isSelected = route === selectedKey;
      const isRoute = !(parent !== undefined && children.length === 0);
      return (
        <CanAccess
          key={route}
          resource={name}
          action="list"
          params={{ resource: item }}
        >
          <Menu.Item
            key={route}
            style={{
              textTransform: 'capitalize',
              color: isSelected ? SECONDARY_TEXT_COLOR : PRIMARY_TEXT_COLOR,
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            {route ? <Link to={route || '/'}>{label}</Link> : label}
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  const handleLogout = () => {
    if (warnWhen) {
      const confirm = window.confirm(t('global.warn.unsavedChanges'));

      if (confirm) {
        setWarnWhen(false);
        mutateLogout();
      }
    } else {
      mutateLogout();
    }
  };

  const logout = isExistAuthentication && (
    <Menu.Item
      style={{ color: PRIMARY_TEXT_COLOR }}
      key="logout"
      onClick={handleLogout}
      icon={<LogoutOutlined />}
    >
      {t('global.buttons.logout', '')}
    </Menu.Item>
  );

  const items = renderTreeView(
    isAdmin ? adminMenuItems : defaultMenuItems,
    selectedKey,
  );

  const renderSider = () => {
    if (render) {
      return render({
        dashboard: null,
        items,
        logout,
        collapsed,
      });
    }
    return (
      <>
        {items}
        {logout}
      </>
    );
  };

  const renderMenu = () => {
    return (
      <Menu
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={[selectedKey]}
        mode="inline"
        style={{
          marginTop: '8px',
          border: 'none',
        }}
        onClick={() => {
          if (isMobileWithTablet) {
            setCollapsed(true);
            setMobileSiderOpen(false);
          }
        }}
      >
        {renderSider()}
      </Menu>
    );
  };

  const renderDrawerSider = () => {
    return (
      <>
        <Drawer
          open={mobileSiderOpen}
          onClose={() => setMobileSiderOpen(false)}
          placement="left"
          closable={false}
          width={200}
          styles={{
            body: {
              padding: 0,
            },
          }}
          maskClosable={true}
        >
          <Layout>
            <Layout.Sider
              style={{
                height: '100vh',
                backgroundColor: token.colorBgContainer,
                borderRight: `1px solid ${token.colorBgElevated}`,
              }}
            >
              <div
                style={{
                  width: '200px',
                  padding: '0 16px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: '64px',
                  backgroundColor: token.colorBgElevated,
                }}
              >
                <Typography.Link
                  style={{
                    display: 'inline-flex',
                    width: '100px',
                    height: '100px',
                  }}
                >
                  <AkselIcon />
                </Typography.Link>
              </div>
              {renderMenu()}
            </Layout.Sider>
          </Layout>
        </Drawer>
        <Button
          style={drawerButtonStyles}
          size="large"
          onClick={() => setMobileSiderOpen(true)}
          icon={<MenuOutlined />}
        />
      </>
    );
  };

  if (isMobileWithTablet) {
    return renderDrawerSider();
  }

  const siderStyle = isMobileWithTablet ? drawerButtonStyles : antLayoutSider;

  return (
    <AntdLayout.Sider
      collapsible
      collapsedWidth={isMobileWithTablet ? 0 : 80}
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      style={{
        ...siderStyle,
        backgroundColor: token.Layout?.siderBg ?? SECONDARY_TEXT_COLOR,
        border: 'none',
        top: 0,
      }}
      trigger={
        !isMobileWithTablet ? (
          <Button
            type="text"
            style={{
              borderRadius: 0,
              height: '100%',
              width: '100%',
              backgroundColor: token.Layout?.siderBg ?? SECONDARY_TEXT_COLOR,
            }}
          >
            {collapsed ? (
              <RightOutlined
                style={{
                  color: token.colorPrimary,
                  border: 0,
                }}
              />
            ) : (
              <LeftOutlined
                style={{
                  color: token.colorPrimary,
                  border: 0,
                }}
              />
            )}
          </Button>
        ) : (
          <div>
            <MenuOutlined />
          </div>
        )
      }
    >
      <div
        style={{
          width: collapsed ? '80px' : '200px',
          padding: collapsed ? '0' : '0 16px',
          display: 'flex',
          justifyContent: collapsed ? 'center' : 'flex-start',
          alignItems: 'center',
          height: '64px',
          backgroundColor: SECONDARY_TEXT_COLOR,
          border: 'none',
          fontSize: '14px',
        }}
      >
        <Typography.Link
          style={{
            display: 'inline-flex',
            width: !collapsed ? '100px' : '50px',
            height: !collapsed ? '100px' : '50px',
          }}
        >
          <AkselIcon />
        </Typography.Link>
      </div>
      {renderMenu()}
    </AntdLayout.Sider>
  );
};
